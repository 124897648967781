import type {
    AEChartingKey,
    AEOrderField,
    AESearchResponse,
    AETableResponse,
    AuAdverseEvent,
    AuMaudeTableResponse,
    CaAdverseEvent,
    CaMaudeTableResponse,
    HistogramResponse
} from '@api';
import emptyApi from '@api/emptyApi';
import type { AuMaudeFieldName, CaMaudeFieldName } from '@api/enums';
import { BasilModule } from '@constants';
import { CountryCode, globalizeApiUrl } from '@content/Country';
import { PossibleArray } from '@helpers/types';
import type { InfinitePageResponse, TablePageArgs } from '../../Misc/DataTable/infinite/types';
import { transformPaginatedResponse } from '../../Misc/DataTable/infinite/util';
import { AETableItem, createEventTableData } from './mapEvents';
import { AEResults, mapResults } from './mapResults';
import type { QuerySelections } from './queries/genericPrepare';
import { createHistogramParams, createTableParams } from './queries/prepareAeQuery';

const guessModule = (selections: QuerySelections) =>
    (selections.dataset || selections.datasetId)
        ? BasilModule.Postmarket
        : BasilModule.Regulatory;

/**
 * Duplicates generated query hooks in order to control the args and return formats.
 *
 * Uses either /pmi/, /exec/regulatory/, or base ae endpoint.
 *
 * Can handle multi-queries with /multi endpoint.
 */

const mapModuleToPath: Record<BasilModule, string> = {
    [BasilModule.Regulatory]: '',
    [BasilModule.BasicRegulatory]: '',
    [BasilModule.Executive]: 'exec/regulatory/',
    [BasilModule.Postmarket]: 'pmi/',
}

const createQuery = (endpoint: string, module: BasilModule, body: any) => ({
    url: `/maude-search/v1/${mapModuleToPath[module]}${endpoint}`,
    method: 'POST',
    body
});

export type AETableSelections = PossibleArray<QuerySelections>;
type Order = {
    us: AEOrderField;
    au: AuMaudeFieldName;
    ca: CaMaudeFieldName;
}
export type AETableArg<C extends CountryCode> = TablePageArgs<AETableSelections, Order[C]>;

const tableQuery = <C extends CountryCode>(country: C) => (args: AETableArg<C>) => {
    const { params, module, ...rest } = args; // Only the params property can be an array.
    if (Array.isArray(params)) {
        const body = {
            queries: params.map(p => createTableParams(p, rest, country))
        };
        return createQuery(
            globalizeApiUrl('search-table', country) + '/multi',
            module,
            body
        );
    }
    return createQuery(
        globalizeApiUrl('search-table', country),
        module,
        createTableParams(params, rest, country)
    );
}

const eventsApi = emptyApi.injectEndpoints({
    endpoints: build => ({
        getEventsTablePage: build.query<InfinitePageResponse<AETableItem>, AETableArg<'us'>>({
            query: tableQuery('us'),
            transformResponse: transformPaginatedResponse<AETableItem, AETableResponse>(createEventTableData)
        }),
        getAuEventsTablePage: build.query<InfinitePageResponse<AuAdverseEvent>, AETableArg<'au'>>({
            query: tableQuery('au'),
            transformResponse: transformPaginatedResponse(
                (result: AuMaudeTableResponse) => result.adverseEvents || []
            )
        }),
        getCaEventsTablePage: build.query<InfinitePageResponse<CaAdverseEvent>, AETableArg<'ca'>>({
            query: tableQuery('ca'),
            transformResponse: transformPaginatedResponse(
                (result: CaMaudeTableResponse) => result.adverseEvents || []
            )
        }),
        getEventsSearchResults: build.query<AEResults, QuerySelections>({
            query: (args) => createQuery(
                'search-page',
                // Note: no support for exec module
                guessModule(args),
                createHistogramParams(args)
            ),
            transformResponse: (result) => {
                const data = result as AESearchResponse;
                return mapResults(data);
            }
        }),
        getEventsHistogram: build.query<HistogramResponse, QuerySelections & { histogram: AEChartingKey }>({
            query: (args) => createQuery(
                'histogram',
                // Note: no support for exec module
                guessModule(args),
                createHistogramParams(args)
            )
        })
    })
})

export const {
    useGetEventsTablePageQuery,
    useGetAuEventsTablePageQuery,
    useGetCaEventsTablePageQuery
} = eventsApi;

export default eventsApi;
