import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchClinicalTrial: build.query<
      SearchClinicalTrialApiResponse,
      SearchClinicalTrialApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/clinical-trial`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGuidance: build.query<
      SearchGuidanceApiResponse,
      SearchGuidanceApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/guidance`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchPmi: build.query<SearchPmiApiResponse, SearchPmiApiArg>({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/search`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchPmiCount: build.query<
      SearchPmiCountApiResponse,
      SearchPmiCountApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/search/count`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getGudidTable: build.query<GetGudidTableApiResponse, GetGudidTableApiArg>({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/search/gudid/table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiDatasetList: build.query<
      GetPmiDatasetListApiResponse,
      GetPmiDatasetListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/dataset`,
        method: "POST",
        body: queryArg,
      }),
    }),
    recallTable: build.query<RecallTableApiResponse, RecallTableApiArg>({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    recallTableMulti: build.query<
      RecallTableMultiApiResponse,
      RecallTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    auRecallTable: build.query<AuRecallTableApiResponse, AuRecallTableApiArg>({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table/international/au`,
        method: "POST",
        body: queryArg,
      }),
    }),
    auRecallTableMulti: build.query<
      AuRecallTableMultiApiResponse,
      AuRecallTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table/international/au/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    caRecallTable: build.query<CaRecallTableApiResponse, CaRecallTableApiArg>({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table/international/ca`,
        method: "POST",
        body: queryArg,
      }),
    }),
    caRecallTableMulti: build.query<
      CaRecallTableMultiApiResponse,
      CaRecallTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/table/international/ca/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    recallHistogram: build.query<
      RecallHistogramApiResponse,
      RecallHistogramApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/recall/histogram`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getReviewPanel: build.query<
      GetReviewPanelApiResponse,
      GetReviewPanelApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getReviewPanelData: build.query<
      GetReviewPanelDataApiResponse,
      GetReviewPanelDataApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/pmi/review-panel/data`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getRegulatoryReviewPanel: build.query<
      GetRegulatoryReviewPanelApiResponse,
      GetRegulatoryReviewPanelApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/regulatory/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getRegulatoryReviewPanelData: build.query<
      GetRegulatoryReviewPanelDataApiResponse,
      GetRegulatoryReviewPanelDataApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/regulatory/review-panel/data`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getRegulatoryReviewPanelTrend: build.query<
      GetRegulatoryReviewPanelTrendApiResponse,
      GetRegulatoryReviewPanelTrendApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/regulatory/review-panel/trend`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicRegulatoryReviewPanel: build.query<
      GetBasicRegulatoryReviewPanelApiResponse,
      GetBasicRegulatoryReviewPanelApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/basic/regulatory/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicRegulatoryReviewPanelData: build.query<
      GetBasicRegulatoryReviewPanelDataApiResponse,
      GetBasicRegulatoryReviewPanelDataApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/basic/regulatory/review-panel/data`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicRegulatoryReviewPanelTrend: build.query<
      GetBasicRegulatoryReviewPanelTrendApiResponse,
      GetBasicRegulatoryReviewPanelTrendApiArg
    >({
      query: (queryArg) => ({
        url: `/device-search/v1/basic/regulatory/review-panel/trend`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicCountByApplication: build.query<
      GetBasicCountByApplicationApiResponse,
      GetBasicCountByApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/basic/count/by-application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicCountByProductCode: build.query<
      GetBasicCountByProductCodeApiResponse,
      GetBasicCountByProductCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/basic/count/by-product-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBassicOuntLatestPublishDate: build.query<
      GetBassicOuntLatestPublishDateApiResponse,
      GetBassicOuntLatestPublishDateApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/basic/count/with-latest-publish-date`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchBasicSearchPage: build.query<
      SearchBasicSearchPageApiResponse,
      SearchBasicSearchPageApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/basic/search-page`,
        method: "POST",
        body: queryArg,
      }),
    }),
    basicSearchTable: build.query<
      BasicSearchTableApiResponse,
      BasicSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/basic/search-table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getCountByApplication: build.query<
      GetCountByApplicationApiResponse,
      GetCountByApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/count/by-application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getCountByProductCode: build.query<
      GetCountByProductCodeApiResponse,
      GetCountByProductCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/count/by-product-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getCountLatestPublishDate: build.query<
      GetCountLatestPublishDateApiResponse,
      GetCountLatestPublishDateApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/count/with-latest-publish-date`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiCountByProductCode: build.query<
      GetPmiCountByProductCodeApiResponse,
      GetPmiCountByProductCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/pmi/count/by-product-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    pmiSearchKey: build.query<PmiSearchKeyApiResponse, PmiSearchKeyApiArg>({
      query: (queryArg) => ({
        url: `/gudid-search/v1/pmi/search/key`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getSearchCount: build.query<
      GetSearchCountApiResponse,
      GetSearchCountApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/pmi/search/count`,
        method: "POST",
        body: queryArg,
      }),
    }),
    gudidSearchPage: build.query<
      GudidSearchPageApiResponse,
      GudidSearchPageApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/search-page`,
        method: "POST",
        body: queryArg,
      }),
    }),
    gudidSearchTable: build.query<
      GudidSearchTableApiResponse,
      GudidSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/search-table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchHistogram: build.query<
      SearchHistogramApiResponse,
      SearchHistogramApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/histogram`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchApplication: build.query<
      SearchApplicationApiResponse,
      SearchApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-search/v1/search/application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchKey: build.query<SearchKeyApiResponse, SearchKeyApiArg>({
      query: (queryArg) => ({
        url: `/gudid-search/v1/search/key`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetBasicCountByApplication: build.query<
      SearchGetBasicCountByApplicationApiResponse,
      SearchGetBasicCountByApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/basic/count/by-application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetBasicCountByProductCode: build.query<
      SearchGetBasicCountByProductCodeApiResponse,
      SearchGetBasicCountByProductCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/basic/count/by-product-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicReviewPanelByDate: build.query<
      GetBasicReviewPanelByDateApiResponse,
      GetBasicReviewPanelByDateApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/basic/count/by-date/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    basicSearch: build.query<BasicSearchApiResponse, BasicSearchApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/basic/search-page`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchBasicSearchTable: build.query<
      SearchBasicSearchTableApiResponse,
      SearchBasicSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/basic/search-table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetCountByApplication: build.query<
      SearchGetCountByApplicationApiResponse,
      SearchGetCountByApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/count/by-application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetCountByProductCode: build.query<
      SearchGetCountByProductCodeApiResponse,
      SearchGetCountByProductCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/count/by-product-code`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getReviewPanelByDate: build.query<
      GetReviewPanelByDateApiResponse,
      GetReviewPanelByDateApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/count/by-date/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    search: build.query<SearchApiResponse, SearchApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-page`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getSearch: build.query<GetSearchApiResponse, GetSearchApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearchTable: build.query<
      SearchSearchTableApiResponse,
      SearchSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchTableMulti: build.query<
      SearchTableMultiApiResponse,
      SearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    auSearchTable: build.query<AuSearchTableApiResponse, AuSearchTableApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/international/au`,
        method: "POST",
        body: queryArg,
      }),
    }),
    auSearchTableMulti: build.query<
      AuSearchTableMultiApiResponse,
      AuSearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/international/au/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    caSearchTable: build.query<CaSearchTableApiResponse, CaSearchTableApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/international/ca`,
        method: "POST",
        body: queryArg,
      }),
    }),
    caSearchTableMulti: build.query<
      CaSearchTableMultiApiResponse,
      CaSearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/international/ca/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getApplication: build.query<
      GetApplicationApiResponse,
      GetApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDevice: build.query<GetDeviceApiResponse, GetDeviceApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search-table/device`,
        method: "POST",
        body: queryArg,
      }),
    }),
    pmiHistogram: build.query<PmiHistogramApiResponse, PmiHistogramApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/histogram`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchCount: build.query<SearchCountApiResponse, SearchCountApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search/count`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchCountMulti: build.query<
      SearchCountMultiApiResponse,
      SearchCountMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search/count/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearchBrand: build.query<
      SearchSearchBrandApiResponse,
      SearchSearchBrandApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/search/brand`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetReviewPanelData: build.query<
      SearchGetReviewPanelDataApiResponse,
      SearchGetReviewPanelDataApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/review-panel/data`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiReviewPanelByDate: build.query<
      GetPmiReviewPanelByDateApiResponse,
      GetPmiReviewPanelByDateApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/pmi/count/by-date/review-panel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearch: build.query<SearchSearchApiResponse, SearchSearchApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-page`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetSearch: build.query<
      SearchGetSearchApiResponse,
      SearchGetSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchTable1: build.query<SearchTable1ApiResponse, SearchTable1ApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearchTableMulti: build.query<
      SearchSearchTableMultiApiResponse,
      SearchSearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchAuSearchTable: build.query<
      SearchAuSearchTableApiResponse,
      SearchAuSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/international/au`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchAuSearchTableMulti: build.query<
      SearchAuSearchTableMultiApiResponse,
      SearchAuSearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/international/au/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchCaSearchTable: build.query<
      SearchCaSearchTableApiResponse,
      SearchCaSearchTableApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/international/ca`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchCaSearchTableMulti: build.query<
      SearchCaSearchTableMultiApiResponse,
      SearchCaSearchTableMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/international/ca/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetApplication: build.query<
      SearchGetApplicationApiResponse,
      SearchGetApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchGetDevice: build.query<
      SearchGetDeviceApiResponse,
      SearchGetDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search-table/device`,
        method: "POST",
        body: queryArg,
      }),
    }),
    histogram1: build.query<Histogram1ApiResponse, Histogram1ApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/histogram`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearchCount: build.query<
      SearchSearchCountApiResponse,
      SearchSearchCountApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search/count`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchSearchCountMulti: build.query<
      SearchSearchCountMultiApiResponse,
      SearchSearchCountMultiApiArg
    >({
      query: (queryArg) => ({
        url: `/maude-search/v1/search/count/multi`,
        method: "POST",
        body: queryArg,
      }),
    }),
    searchBrand1: build.query<SearchBrand1ApiResponse, SearchBrand1ApiArg>({
      query: (queryArg) => ({
        url: `/maude-search/v1/search/brand`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getViewableFile: build.query<
      GetViewableFileApiResponse,
      GetViewableFileApiArg
    >({
      query: () => ({ url: `/search/v2/common/view`, method: "POST" }),
    }),
    getDownload: build.query<GetDownloadApiResponse, GetDownloadApiArg>({
      query: () => ({ url: `/search/v2/common/download`, method: "POST" }),
    }),
    regulationCount: build.query<
      RegulationCountApiResponse,
      RegulationCountApiArg
    >({
      query: (queryArg) => ({
        url: `/search/v2/regulatory/regulation/count`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDetail: build.query<GetDetailApiResponse, GetDetailApiArg>({
      query: (queryArg) => ({
        url: `/gudid-data/v1/basic/gudid`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDetailByApplicationNumber: build.query<
      GetDetailByApplicationNumberApiResponse,
      GetDetailByApplicationNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/basic/gudid/by-application-number`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBrandList: build.query<GetBrandListApiResponse, GetBrandListApiArg>({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/brand`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataGetCompanyList: build.query<
      DataGetCompanyListApiResponse,
      DataGetCompanyListApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/company`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getByBrandName: build.query<
      GetByBrandNameApiResponse,
      GetByBrandNameApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/by-brand-name`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getByCompanyName: build.query<
      GetByCompanyNameApiResponse,
      GetByCompanyNameApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/by-company-name`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getByFamilyCompanyId: build.query<
      GetByFamilyCompanyIdApiResponse,
      GetByFamilyCompanyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/by-family-company-id`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getByFamilyBrandId: build.query<
      GetByFamilyBrandIdApiResponse,
      GetByFamilyBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/family/by-family-brand-id`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getByTerm: build.query<GetByTermApiResponse, GetByTermApiArg>({
      query: (queryArg) => ({
        url: `/gudid-data/v1/gmdn/by-term`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataGetDetail: build.query<DataGetDetailApiResponse, DataGetDetailApiArg>({
      query: (queryArg) => ({
        url: `/gudid-data/v1/gudid`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataGetDetailByApplicationNumber: build.query<
      DataGetDetailByApplicationNumberApiResponse,
      DataGetDetailByApplicationNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/gudid/by-application-number`,
        method: "POST",
        body: queryArg,
      }),
    }),
    pmiGetByFamilyBrandId: build.query<
      PmiGetByFamilyBrandIdApiResponse,
      PmiGetByFamilyBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/gudid-data/v1/pmi/family/by-family-brand-id`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiDetail: build.query<GetPmiDetailApiResponse, GetPmiDetailApiArg>({
      query: (queryArg) => ({
        url: `/gudid-data/v1/pmi/gudid`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataPostBasicRegulationList: build.query<
      DataPostBasicRegulationListApiResponse,
      DataPostBasicRegulationListApiArg
    >({
      query: (queryArg) => ({
        url: `/data/v2/basic/regulation/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataGetBasicRegulationDetails: build.query<
      DataGetBasicRegulationDetailsApiResponse,
      DataGetBasicRegulationDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/data/v2/basic/regulation/${queryArg}` }),
    }),
    dataPostRegulationList: build.query<
      DataPostRegulationListApiResponse,
      DataPostRegulationListApiArg
    >({
      query: (queryArg) => ({
        url: `/data/v2/regulatory/regulation/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    dataGetRegulationDetails: build.query<
      DataGetRegulationDetailsApiResponse,
      DataGetRegulationDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/data/v2/regulatory/regulation/${queryArg}`,
      }),
    }),
    addStats: build.mutation<AddStatsApiResponse, AddStatsApiArg>({
      query: (queryArg) => ({
        url: `/user-data/v1/common/user/stats`,
        method: "POST",
        body: queryArg,
      }),
    }),
    tenantSettings: build.query<
      TenantSettingsApiResponse,
      TenantSettingsApiArg
    >({
      query: () => ({ url: `/user-data/v1/common/tenant/settings` }),
    }),
    pmiGetSavedDataForDashboard: build.query<
      PmiGetSavedDataForDashboardApiResponse,
      PmiGetSavedDataForDashboardApiArg
    >({
      query: () => ({ url: `/user-data/v1/pmi/user/saved/dashboard` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type SearchClinicalTrialApiResponse =
  /** status 200 Successful Response */ SearchClinicalTrialResponse;
export type SearchClinicalTrialApiArg = SearchClinicalTrialQuery;
export type SearchGuidanceApiResponse =
  /** status 200 Successful Response */ SearchGuidanceResponse;
export type SearchGuidanceApiArg = SearchGuidanceQuery;
export type SearchPmiApiResponse =
  /** status 200 Successful Response */ SearchPmiResponse;
export type SearchPmiApiArg = PmiQuery;
export type SearchPmiCountApiResponse =
  /** status 200 Successful Response */ SearchPmiCountResponse;
export type SearchPmiCountApiArg = PmiQuery;
export type GetGudidTableApiResponse =
  /** status 200 Successful Response */ GudidTableResponse;
export type GetGudidTableApiArg = GudidTableQuery;
export type GetPmiDatasetListApiResponse =
  /** status 200 Successful Response */ DatasetResponse;
export type GetPmiDatasetListApiArg = ReviewPanelPmiQuery;
export type RecallTableApiResponse =
  /** status 200 Successful Response */ RecallTableResponse;
export type RecallTableApiArg = RecallQuery;
export type RecallTableMultiApiResponse =
  /** status 200 Successful Response */ RecallTableResponse;
export type RecallTableMultiApiArg = RecallMultiQuery;
export type AuRecallTableApiResponse =
  /** status 200 Successful Response */ AuRecallTableResponse;
export type AuRecallTableApiArg = AuRecallQuery;
export type AuRecallTableMultiApiResponse =
  /** status 200 Successful Response */ AuRecallTableResponse;
export type AuRecallTableMultiApiArg = AuRecallMultiQuery;
export type CaRecallTableApiResponse =
  /** status 200 Successful Response */ CaRecallTableResponse;
export type CaRecallTableApiArg = CaRecallQuery;
export type CaRecallTableMultiApiResponse =
  /** status 200 Successful Response */ CaRecallTableResponse;
export type CaRecallTableMultiApiArg = CaRecallMultiQuery;
export type RecallHistogramApiResponse =
  /** status 200 Successful Response */ RecallHistogramResponse;
export type RecallHistogramApiArg = RecallHistogramQuery;
export type GetReviewPanelApiResponse =
  /** status 200 Successful Response */ ReviewPanelPmiResponse;
export type GetReviewPanelApiArg = ReviewPanelPmiQuery;
export type GetReviewPanelDataApiResponse =
  /** status 200 Successful Response */ ReviewPanelDataResponse;
export type GetReviewPanelDataApiArg = ReviewPanelDataQuery;
export type GetRegulatoryReviewPanelApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryResponse;
export type GetRegulatoryReviewPanelApiArg = ReviewPanelRegulatoryQuery;
export type GetRegulatoryReviewPanelDataApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryDataResponse;
export type GetRegulatoryReviewPanelDataApiArg = ReviewPanelRegulatoryDataQuery;
export type GetRegulatoryReviewPanelTrendApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryTrendResponse;
export type GetRegulatoryReviewPanelTrendApiArg =
  ReviewPanelRegulatoryTrendQuery;
export type GetBasicRegulatoryReviewPanelApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryResponse;
export type GetBasicRegulatoryReviewPanelApiArg = ReviewPanelRegulatoryQuery;
export type GetBasicRegulatoryReviewPanelDataApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryDataResponse;
export type GetBasicRegulatoryReviewPanelDataApiArg =
  ReviewPanelRegulatoryDataQuery;
export type GetBasicRegulatoryReviewPanelTrendApiResponse =
  /** status 200 Successful Response */ ReviewPanelRegulatoryTrendResponse;
export type GetBasicRegulatoryReviewPanelTrendApiArg =
  ReviewPanelRegulatoryTrendQuery;
export type GetBasicCountByApplicationApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type GetBasicCountByApplicationApiArg =
  BodyGetBasicCountByApplicationGudidSearchV1BasicCountByApplicationPost;
export type GetBasicCountByProductCodeApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type GetBasicCountByProductCodeApiArg =
  BodyGetBasicCountByProductCodeGudidSearchV1BasicCountByProductCodePost;
export type GetBassicOuntLatestPublishDateApiResponse =
  /** status 200 Successful Response */ CountWithLatestPublishDateResponse;
export type GetBassicOuntLatestPublishDateApiArg = CountQuery;
export type SearchBasicSearchPageApiResponse =
  /** status 200 Successful Response */ SearchPageResponse;
export type SearchBasicSearchPageApiArg = SearchDeviceQuery;
export type BasicSearchTableApiResponse =
  /** status 200 Successful Response */ SearchTableResponse;
export type BasicSearchTableApiArg = SearchTableQuery;
export type GetCountByApplicationApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type GetCountByApplicationApiArg =
  BodyGetCountByApplicationGudidSearchV1CountByApplicationPost;
export type GetCountByProductCodeApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type GetCountByProductCodeApiArg =
  BodyGetCountByProductCodeGudidSearchV1CountByProductCodePost;
export type GetCountLatestPublishDateApiResponse =
  /** status 200 Successful Response */ CountWithLatestPublishDateResponse;
export type GetCountLatestPublishDateApiArg = CountQuery;
export type GetPmiCountByProductCodeApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type GetPmiCountByProductCodeApiArg =
  BodyGetPmiCountByProductCodeGudidSearchV1PmiCountByProductCodePost;
export type PmiSearchKeyApiResponse =
  /** status 200 Successful Response */ SearchKeyResponse;
export type PmiSearchKeyApiArg = SearchKeyQuery;
export type GetSearchCountApiResponse =
  /** status 200 Successful Response */ PmiCountResponse;
export type GetSearchCountApiArg =
  BodyGetSearchCountGudidSearchV1PmiSearchCountPost;
export type GudidSearchPageApiResponse =
  /** status 200 Successful Response */ SearchPageResponse;
export type GudidSearchPageApiArg = SearchDeviceQuery;
export type GudidSearchTableApiResponse =
  /** status 200 Successful Response */ SearchTableResponse;
export type GudidSearchTableApiArg = SearchTableQuery;
export type SearchHistogramApiResponse =
  /** status 200 Successful Response */ HistogramResponse;
export type SearchHistogramApiArg = HistogramQuery;
export type SearchApplicationApiResponse =
  /** status 200 Successful Response */ SearchApplicationResponse;
export type SearchApplicationApiArg = SearchApplicationQuery;
export type SearchKeyApiResponse =
  /** status 200 Successful Response */ SearchKeyResponse;
export type SearchKeyApiArg = SearchKeyQuery;
export type SearchGetBasicCountByApplicationApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type SearchGetBasicCountByApplicationApiArg =
  BodyGetBasicCountByApplicationMaudeSearchV1BasicCountByApplicationPost;
export type SearchGetBasicCountByProductCodeApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type SearchGetBasicCountByProductCodeApiArg =
  BodyGetBasicCountByProductCodeMaudeSearchV1BasicCountByProductCodePost;
export type GetBasicReviewPanelByDateApiResponse =
  /** status 200 Successful Response */ ReviewPanelCountResponse;
export type GetBasicReviewPanelByDateApiArg = MaudeSearchQuery;
export type BasicSearchApiResponse =
  /** status 200 Successful Response */ MaudeSearchPageResponse;
export type BasicSearchApiArg = MaudeSearchQuery;
export type SearchBasicSearchTableApiResponse =
  /** status 200 Successful Response */ MaudeTableResponse;
export type SearchBasicSearchTableApiArg = MaudeTableQuery;
export type SearchGetCountByApplicationApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type SearchGetCountByApplicationApiArg =
  BodyGetCountByApplicationMaudeSearchV1CountByApplicationPost;
export type SearchGetCountByProductCodeApiResponse =
  /** status 200 Successful Response */ CountResponse;
export type SearchGetCountByProductCodeApiArg =
  BodyGetCountByProductCodeMaudeSearchV1CountByProductCodePost;
export type GetReviewPanelByDateApiResponse =
  /** status 200 Successful Response */ ReviewPanelCountResponse;
export type GetReviewPanelByDateApiArg = MaudeSearchQuery;
export type SearchApiResponse =
  /** status 200 Successful Response */ MaudeSearchPageResponse;
export type SearchApiArg = MaudeSearchQuery;
export type GetSearchApiResponse =
  /** status 200 Successful Response */ MaudePmiResponse;
export type GetSearchApiArg = MaudeSearchQuery;
export type SearchSearchTableApiResponse =
  /** status 200 Successful Response */ MaudeTableResponse;
export type SearchSearchTableApiArg = MaudeTableQuery;
export type SearchTableMultiApiResponse =
  /** status 200 Successful Response */ MaudeTableResponse;
export type SearchTableMultiApiArg = MaudeTableMultiQuery;
export type AuSearchTableApiResponse =
  /** status 200 Successful Response */ AuMaudeTableResponse;
export type AuSearchTableApiArg = AuMaudeTableQuery;
export type AuSearchTableMultiApiResponse =
  /** status 200 Successful Response */ AuMaudeTableResponse;
export type AuSearchTableMultiApiArg = AuMaudeTableMultiQuery;
export type CaSearchTableApiResponse =
  /** status 200 Successful Response */ CaMaudeTableResponse;
export type CaSearchTableApiArg = CaMaudeTableQuery;
export type CaSearchTableMultiApiResponse =
  /** status 200 Successful Response */ CaMaudeTableResponse;
export type CaSearchTableMultiApiArg = CaMaudeTableMultiQuery;
export type GetApplicationApiResponse =
  /** status 200 Successful Response */ ApplicationResponse;
export type GetApplicationApiArg = MaudeTableQuery;
export type GetDeviceApiResponse =
  /** status 200 Successful Response */ DeviceResponse;
export type GetDeviceApiArg = MaudeTableQuery;
export type PmiHistogramApiResponse =
  /** status 200 Successful Response */ MaudeHistogramResponse;
export type PmiHistogramApiArg = MaudeHistogramQuery;
export type SearchCountApiResponse =
  /** status 200 Successful Response */ MaudeCountResponse;
export type SearchCountApiArg = MaudeSearchQuery;
export type SearchCountMultiApiResponse =
  /** status 200 Successful Response */ MaudeMultiCountResponse;
export type SearchCountMultiApiArg = MaudeMultiSearchQuery;
export type SearchSearchBrandApiResponse =
  /** status 200 Successful Response */ UsMaudeBrandMatrixResponse;
export type SearchSearchBrandApiArg = MaudeBrandQuery;
export type SearchGetReviewPanelDataApiResponse =
  /** status 200 Successful Response */ MaudeReviewPanelDataResponse;
export type SearchGetReviewPanelDataApiArg = MaudeReviewPanelQuery;
export type GetPmiReviewPanelByDateApiResponse =
  /** status 200 Successful Response */ ReviewPanelCountResponse;
export type GetPmiReviewPanelByDateApiArg = MaudeSearchQuery;
export type SearchSearchApiResponse =
  /** status 200 Successful Response */ MaudeSearchPageResponse;
export type SearchSearchApiArg = MaudeSearchQuery;
export type SearchGetSearchApiResponse =
  /** status 200 Successful Response */ MaudePmiResponse;
export type SearchGetSearchApiArg = MaudeSearchQuery;
export type SearchTable1ApiResponse =
  /** status 200 Successful Response */ MaudeTableResponse;
export type SearchTable1ApiArg = MaudeTableQuery;
export type SearchSearchTableMultiApiResponse =
  /** status 200 Successful Response */ MaudeTableResponse;
export type SearchSearchTableMultiApiArg = MaudeTableMultiQuery;
export type SearchAuSearchTableApiResponse =
  /** status 200 Successful Response */ AuMaudeTableResponse;
export type SearchAuSearchTableApiArg = AuMaudeTableQuery;
export type SearchAuSearchTableMultiApiResponse =
  /** status 200 Successful Response */ AuMaudeTableResponse;
export type SearchAuSearchTableMultiApiArg = AuMaudeTableMultiQuery;
export type SearchCaSearchTableApiResponse =
  /** status 200 Successful Response */ CaMaudeTableResponse;
export type SearchCaSearchTableApiArg = CaMaudeTableQuery;
export type SearchCaSearchTableMultiApiResponse =
  /** status 200 Successful Response */ CaMaudeTableResponse;
export type SearchCaSearchTableMultiApiArg = CaMaudeTableMultiQuery;
export type SearchGetApplicationApiResponse =
  /** status 200 Successful Response */ ApplicationResponse;
export type SearchGetApplicationApiArg = MaudeTableQuery;
export type SearchGetDeviceApiResponse =
  /** status 200 Successful Response */ DeviceResponse;
export type SearchGetDeviceApiArg = MaudeTableQuery;
export type Histogram1ApiResponse =
  /** status 200 Successful Response */ MaudeHistogramResponse;
export type Histogram1ApiArg = MaudeHistogramQuery;
export type SearchSearchCountApiResponse =
  /** status 200 Successful Response */ MaudeCountResponse;
export type SearchSearchCountApiArg = MaudeSearchQuery;
export type SearchSearchCountMultiApiResponse =
  /** status 200 Successful Response */ MaudeMultiCountResponse;
export type SearchSearchCountMultiApiArg = MaudeMultiSearchQuery;
export type SearchBrand1ApiResponse =
  /** status 200 Successful Response */ UsMaudeBrandMatrixResponse;
export type SearchBrand1ApiArg = MaudeBrandQuery;
export type GetViewableFileApiResponse =
  /** status 200 Successful Response */ any;
export type GetViewableFileApiArg = void;
export type GetDownloadApiResponse = /** status 200 Successful Response */ any;
export type GetDownloadApiArg = void;
export type RegulationCountApiResponse =
  /** status 200 Successful Response */ any;
export type RegulationCountApiArg = SearchRegulationQuery;
export type GetDetailApiResponse =
  /** status 200 Successful Response */ Gudid[];
export type GetDetailApiArg = Keys;
export type GetDetailByApplicationNumberApiResponse =
  /** status 200 Successful Response */ Gudid[];
export type GetDetailByApplicationNumberApiArg =
  BodyGetDetailByApplicationNumberGudidDataV1BasicGudidByApplicationNumberPost;
export type GetBrandListApiResponse = /** status 200 Successful Response */ {
  [key: string]: string | null;
};
export type GetBrandListApiArg = FamilyBrandNames;
export type DataGetCompanyListApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string | null;
  };
export type DataGetCompanyListApiArg = FamilyCompanyNames;
export type GetByBrandNameApiResponse = /** status 200 Successful Response */ {
  [key: string]: string | null;
};
export type GetByBrandNameApiArg = BrandNames;
export type GetByCompanyNameApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string | null;
  };
export type GetByCompanyNameApiArg = CompanyNames;
export type GetByFamilyCompanyIdApiResponse =
  /** status 200 Successful Response */ FamilyCompanyResponse;
export type GetByFamilyCompanyIdApiArg =
  BodyGetByFamilyCompanyIdGudidDataV1FamilyByFamilyCompanyIdPost;
export type GetByFamilyBrandIdApiResponse =
  /** status 200 Successful Response */ FamilyBrandResponse;
export type GetByFamilyBrandIdApiArg =
  BodyGetByFamilyBrandIdGudidDataV1FamilyByFamilyBrandIdPost;
export type GetByTermApiResponse = /** status 200 Successful Response */ Gmdn[];
export type GetByTermApiArg = BodyGetByTermGudidDataV1GmdnByTermPost;
export type DataGetDetailApiResponse =
  /** status 200 Successful Response */ Gudid[];
export type DataGetDetailApiArg = Keys;
export type DataGetDetailByApplicationNumberApiResponse =
  /** status 200 Successful Response */ Gudid[];
export type DataGetDetailByApplicationNumberApiArg =
  BodyGetDetailByApplicationNumberGudidDataV1GudidByApplicationNumberPost;
export type PmiGetByFamilyBrandIdApiResponse =
  /** status 200 Successful Response */ FamilyBrandResponse;
export type PmiGetByFamilyBrandIdApiArg =
  BodyPmiGetByFamilyBrandIdGudidDataV1PmiFamilyByFamilyBrandIdPost;
export type GetPmiDetailApiResponse =
  /** status 200 Successful Response */ Gudid[];
export type GetPmiDetailApiArg = Keys;
export type DataPostBasicRegulationListApiResponse =
  /** status 200 Successful Response */ BasicRegulation[];
export type DataPostBasicRegulationListApiArg = RegulationQuery;
export type DataGetBasicRegulationDetailsApiResponse =
  /** status 200 Successful Response */ BasicRegulation;
export type DataGetBasicRegulationDetailsApiArg = string;
export type DataPostRegulationListApiResponse =
  /** status 200 Successful Response */ Regulation[];
export type DataPostRegulationListApiArg = RegulationQuery;
export type DataGetRegulationDetailsApiResponse =
  /** status 200 Successful Response */ Regulation;
export type DataGetRegulationDetailsApiArg = string;
export type AddStatsApiResponse =
  /** status 200 Successful Response */ StatsResponse;
export type AddStatsApiArg = Stat;
export type TenantSettingsApiResponse =
  /** status 200 Successful Response */ any;
export type TenantSettingsApiArg = void;
export type PmiGetSavedDataForDashboardApiResponse =
  /** status 200 Successful Response */ SavedDataResponse;
export type PmiGetSavedDataForDashboardApiArg = void;
export type DeviceApplication = {
  applicationNumber?: string | null;
  applicationType?: string | null;
  deviceName?: string | null;
  supplementNumber?: string | null;
};
export type ClinicalTrialLocation = {
  city?: string | null;
  country?: string | null;
  facility?: string | null;
  state?: string | null;
  zip?: string | null;
  status?: string | null;
};
export type ClinicalTrial = {
  nctId?: string | null;
  drugs?: string[] | null;
  highlight?: object | null;
  ingredients?: string[] | null;
  acronym?: string | null;
  briefTitle?: string | null;
  collaborators?: string[] | null;
  conditions?: string[] | null;
  count?: number | null;
  devices?: DeviceApplication[] | null;
  enrollmentCount?: number | null;
  enrollmentType?: string | null;
  hasResults?: boolean | null;
  interventions?: string[] | null;
  locations?: ClinicalTrialLocation[] | null;
  nctIdAlias?: string[] | null;
  officialTitle?: string | null;
  phases?: string[] | null;
  sponsors?: string[] | null;
  studyDescription?: string | null;
  studyLeadSponsorClass?: string | null;
  studySourceUrl?: string | null;
  studyStatus?: string | null;
  studySummary?: string | null;
  studyType?: string | null;
  textSections?: string[] | null;
  completionDate?: string | null;
  completionDateType?: string | null;
  delayedPosting?: string | null;
  dispFirstPostDate?: string | null;
  dispFirstPostDateType?: string | null;
  dispFirstSubmitDate?: string | null;
  dispFirstSubmitDateType?: string | null;
  lastUpdatePostDate?: string | null;
  lastUpdatePostDateType?: string | null;
  lastUpdateSubmitDate?: string | null;
  lastUpdateSubmitDateType?: string | null;
  primaryCompletionDate?: string | null;
  primaryCompletionDateType?: string | null;
  resultsFirstPostDate?: string | null;
  resultsFirstPostDateType?: string | null;
  resultsFirstSubmitDate?: string | null;
  resultsFirstSubmitQcDate?: string | null;
  startDate?: string | null;
  startDateType?: string | null;
  statusVerifiedDate?: string | null;
  studyFirstPostDate?: string | null;
  studyFirstPostDateType?: string | null;
  studyFirstSubmitDate?: string | null;
  studyFirstSubmitQcDate?: string | null;
  latestStatusChangeDate?: string | null;
};
export type TotalResults = {
  relation: string;
  value: number;
};
export type SearchClinicalTrialResponse = {
  results: ClinicalTrial[];
  facets?: object | null;
  took: number;
  totalResults: TotalResults;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ClinicalTrialFieldEnum =
  | "COLLABORATOR_FILTER"
  | "CONDITION"
  | "CONDITION_FILTER"
  | "DRUG_BRAND"
  | "INGREDIENT_ACTIVE_CODE"
  | "INGREDIENT_INACTIVE_CODE"
  | "INGREDIENT_ACTIVE_NAME"
  | "INGREDIENT_INACTIVE_NAME"
  | "INTERVENTION_TYPE"
  | "LAST_UPDATE_DATE"
  | "MEDDRA_HIGH_LEVEL_GROUP_TERM"
  | "MEDDRA_HIGH_LEVEL_TERM"
  | "MEDDRA_PREFERRED_TERM"
  | "MEDDRA_SYSTEM_ORGAN_CLASS"
  | "NCT_ID"
  | "PARTICIPANT_COUNT"
  | "PHASE"
  | "RELATED_APP_TYPE"
  | "RESULTS_POSTED"
  | "SECTION"
  | "SPONSOR_COLLABORATOR"
  | "SPONSOR_FILTER"
  | "START_DATE"
  | "STATUS"
  | "STUDY_TYPE";
export type OperatorEnum = "OR" | "AND";
export type PeriodEnum = "WEEK" | "MONTH";
export type SearchTypeEnum = "FILTER" | "QUERY";
export type ClinicalTrialFilter = {
  field: ClinicalTrialFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type ClinicalTrialFieldNameEnum =
  | "LAST_UPDATE_DATE"
  | "PARTICIPANT_COUNT"
  | "POSTED_DATE"
  | "START_DATE";
export type ClinicalTrialOrderBy = {
  fieldName: ClinicalTrialFieldNameEnum;
  ascending: boolean;
};
export type SearchClinicalTrialQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: ClinicalTrialFilter[];
  orderBy?: ClinicalTrialOrderBy[];
  exactDrugMatch?: boolean;
  includeFacets?: boolean;
};
export type Guidance = {
  center?: string | null;
  commentCloseDate?: string | null;
  communicationType?: string | null;
  docketNumber?: string | null;
  docketUrl?: string | null;
  finalGuidance?: string | null;
  issueDate?: string | null;
  openComment?: boolean | null;
  primaryId?: string | null;
  primaryIdHash?: string | null;
  productField?: string[] | string | null;
  title?: string | null;
  url?: string | null;
  highlight?: object | null;
};
export type SearchGuidanceResponse = {
  results: Guidance[];
};
export type GuidanceFieldEnum =
  | "CENTER"
  | "COMMUNICATION_TYPE"
  | "DECISION_DATE"
  | "ISSUE_DATE"
  | "PRIMARY_ID_HASH"
  | "PRODUCT_FIELD";
export type GuidanceFilter = {
  field: GuidanceFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type GuidanceFieldNameEnum = "ISSUE_DATE";
export type GuidanceOrderBy = {
  fieldName: GuidanceFieldNameEnum;
  ascending: boolean;
};
export type SearchGuidanceQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: GuidanceFilter[];
  orderBy?: GuidanceOrderBy[];
};
export type DateFacet = {
  min?: string | null;
  max?: string | null;
};
export type TermFacet = {
  key: string;
  count?: number;
  count2?: number | null;
  count3?: number | null;
  isSelected: boolean;
  meta?: (number | string)[] | null;
  family?: number | null;
};
export type FieldEnum =
  | "APPLICATION_NUMBER"
  | "APPLICATION_TYPE"
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "DEVICE_PUBLISH_DATE"
  | "ESTABLISHMENT_NAME"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "GMDN"
  | "PRODUCT_CODE"
  | "RECORD_KEY"
  | "SINGLE_USE";
export type FamilyCompanyTermFacet = {
  key: string;
  count?: number;
  count2?: number | null;
  count3?: number | null;
  isSelected: boolean;
  meta?: (number | string)[] | null;
  family?: number | null;
  filterField?: FieldEnum;
};
export type ProductCodeTermFacet = {
  key: string;
  count?: number;
  count2?: number | null;
  count3?: number | null;
  isSelected: boolean;
  meta?: (number | string)[] | null;
  family?: number | null;
  device?: string | null;
};
export type PmiFacets = {
  dateReceived?: DateFacet | null;
  deviceProblem?: TermFacet[] | null;
  establishmentName?: TermFacet[] | null;
  eventType?: TermFacet[] | null;
  familyBrand?: TermFacet[] | null;
  familyCompany?: FamilyCompanyTermFacet[] | null;
  fdaDeterminedCause?: TermFacet[] | null;
  gmdn?: TermFacet[] | null;
  manufacturer?: TermFacet[] | null;
  ownerOperator?: TermFacet[] | null;
  patientProblem?: TermFacet[] | null;
  productCode?: ProductCodeTermFacet[] | null;
  recallClass?: TermFacet[] | null;
  recallStatus?: TermFacet[] | null;
  reportSourceCode?: TermFacet[] | null;
  reportType?: TermFacet[] | null;
  reportedOutcome?: TermFacet[] | null;
  singleUseLabeled?: TermFacet[] | null;
  singleUseReprocessed?: TermFacet[] | null;
};
export type ApplicationNumber = {
  kNumbers?: string[] | null;
  hdeNumbers?: string[] | null;
  pmaNumbers?: string[] | null;
};
export type RecallApplication = {
  number: string;
  type: string;
};
export type RecallFirm = {
  addressString?: string | null;
  name?: string | null;
};
export type Recall = {
  applications?: RecallApplication[] | null;
  dateCreate?: string | null;
  dateInitiatedByFirm?: string | null;
  datePosted?: string | null;
  dateTerminated?: string | null;
  eventId?: string | null;
  fdaDeterminedCauses?: string[] | null;
  manufacturerReasonsForRecall?: string[] | null;
  mergedDate?: string | null;
  recallClasses?: number[] | null;
  recallNumber?: string | null;
  recallStatus?: string | null;
  recallingFirm?: RecallFirm | null;
};
export type RecallStatus = {
  status?: string | null;
  count?: number | null;
};
export type RecallEvent = {
  applicationNumbers?: ApplicationNumber | null;
  dateCreate?: string | null;
  dateInitiatedByFirm?: string | null;
  datePosted?: string | null;
  dateTerminated?: string | null;
  eventId?: string | null;
  fdaDeterminedCauses?: string[] | null;
  manufacturerReasonsForRecall?: string[] | null;
  mergedDate?: string | null;
  numberOfApplications?: number | null;
  recallClasses?: number[] | null;
  recallingFirmNames?: string[] | null;
  recalls?: Recall[] | null;
  statuses?: RecallStatus[] | null;
  firstForApplications?: string[] | null;
};
export type PmiRecall = {
  events?: RecallEvent[] | null;
};
export type PmiTotalResults = {
  adverseEvent?: TotalResults | null;
  recall?: TotalResults | null;
};
export type SearchPmiResponse = {
  appliedQuery: object;
  facets: PmiFacets;
  recall?: PmiRecall | null;
  took: number;
  totalResults: PmiTotalResults;
  withinCounts?: object | null;
};
export type PmiFieldEnum =
  | "ADVERSE_EVENT_BRAND_NAME"
  | "APPLICATION_NUMBER"
  | "BASIL_DATE_CREATED"
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "CREATED_DATE"
  | "DATE"
  | "DEVICE_PROBLEM"
  | "ESTABLISHMENT_NAME"
  | "EVENT_TYPE"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "FDA_DETERMINED_CAUSE"
  | "GMDN"
  | "MANUFACTURER"
  | "PATIENT_PROBLEM"
  | "PRODUCT_CODE"
  | "RECALL_BRAND_NAME"
  | "RECALL_CLASS"
  | "RECALL_STATUS"
  | "REPORT_SOURCE_CODE"
  | "REPORTED_OUTCOME"
  | "REPORT_TYPE"
  | "REVIEW_PANEL"
  | "SINGLE_USE_LABELED"
  | "SINGLE_USE_REPROCESSED";
export type PmiFilter = {
  field: PmiFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type PmiFieldNameEnum =
  | "APPLICATION_NUMBER"
  | "BRAND_NAME"
  | "EVENT_TYPE"
  | "MANUFACTURER"
  | "PRODUCT_CODE"
  | "RECEIVED_DATE"
  | "RELEVANCE"
  | "REPORT_KEY";
export type PmiOrderBy = {
  fieldName: PmiFieldNameEnum;
  ascending: boolean;
};
export type PmiWithinEnum =
  | "ACTION"
  | "AE_BRAND_NAME"
  | "BRAND_NAME"
  | "CODE_INFORMATION"
  | "COMPANY_NAME"
  | "DEVICE_PROBLEM"
  | "EVENTS"
  | "MANUFACTURER_REASON"
  | "NARRATIVE"
  | "PATIENT_PROBLEM";
export type PmiLimitToEnum = "ADVERSE_EVENT" | "RECALL";
export type PmiQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: PmiFilter[];
  orderBy?: PmiOrderBy[];
  datasetId?: string | null;
  dataset?: object | null;
  datasetDefaults?: boolean | null;
  within?: PmiWithinEnum | null;
  limitTo?: PmiLimitToEnum | null;
};
export type SearchPmiCountResponse = {
  appliedQuery: object;
  adverseEvent?: number | null;
  recallEvent?: number | null;
  recallProduct?: number | null;
  took: number;
};
export type ApplicationDevice = {
  brandName?: string | null;
  catalogNumber?: string | null;
  companyName?: string | null;
  deviceCommDistributionEndDate?: string | null;
  deviceCommDistributionStatus?: string | null;
  deviceDescription?: string | null;
  devicePublishDate?: string | null;
  familyCompanyName?: string | null;
  familyCompanySubName?: string | null;
  publicDeviceRecordKey?: string | null;
  versionModelNumber?: string | null;
};
export type GudidTableResponse = {
  appliedSearch?: object | null;
  gudids: ApplicationDevice[];
  took: object;
  totalResults: TotalResults;
};
export type GudidFieldNameEnum =
  | "BRAND_NAME"
  | "CATALOG_NUMBER"
  | "COMPANY_NAME"
  | "DISTRIBUTION_END_DATE"
  | "DISTRIBUTION_STATUS"
  | "DEVICE_DESCRIPTION"
  | "DEVICE_PUBLISH_DATE"
  | "VERSION_MODEL_NUMBER";
export type GudidOrderBy = {
  fieldName?: GudidFieldNameEnum;
  ascending?: boolean;
};
export type GudidTableQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: PmiFilter[];
  orderBy?: GudidOrderBy[] | null;
  datasetId?: string | null;
  dataset?: object | null;
  datasetDefaults?: boolean | null;
  within?: PmiWithinEnum | null;
  limitTo?: PmiLimitToEnum | null;
};
export type DatasetCombined = {
  adverseEventCount?: number;
  key: string;
  recallCount?: number;
  recallCountProduct?: number;
  device?: string | null;
  name: string;
};
export type DatasetResponse = {
  datasets?: DatasetCombined[] | null;
  totalTime: number;
};
export type DateRangeEnum = "7" | "30" | "60" | "90" | "180" | "1Y";
export type ReviewPanelPmiLimitToEnum =
  | "ADVERSE_EVENT"
  | "INSPECTION"
  | "RECALL"
  | "WARNING_LETTER";
export type ReviewPanelPmiQuery = {
  dateRange: DateRangeEnum;
  limitTo?: ReviewPanelPmiLimitToEnum | null;
};
export type CauseCount = {
  cause: string;
  count: number;
};
export type Cause = {
  causeCounts: CauseCount[];
  totalCount: number;
};
export type RecallTableResponse = {
  causes: Cause;
  events?: RecallEvent[] | null;
  took: object;
  totalResults: TotalResults;
};
export type RecallFieldEnum =
  | "APPLICATION_NUMBER"
  | "BASIL_DATE_CREATED"
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "DATE_INITIATED"
  | "DATE_MERGED"
  | "ESTABLISHMENT_NAME"
  | "EVENT_ID"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "FDA_DETERMINED_CAUSE"
  | "GMDN"
  | "GUDID"
  | "PRODUCT_CODE"
  | "RECALL_BRAND_NAME"
  | "RECALL_CLASS"
  | "RECALL_NUMBER"
  | "RECALL_STATUS"
  | "RECALLING_FIRM"
  | "REVIEW_PANEL";
export type RecallFilter = {
  field: RecallFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type RecallFieldNameEnum = "RELEVANCE";
export type RecallOrderBy = {
  fieldName: RecallFieldNameEnum;
  ascending: boolean;
};
export type RecallWithinEnum =
  | "ACTION"
  | "BRAND_NAME"
  | "CODE_INFORMATION"
  | "COMPANY_NAME"
  | "MANUFACTURER_REASON";
export type RecallQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: RecallFilter[];
  orderBy?: RecallOrderBy[];
  datasetId?: string | null;
  dataset?: object | null;
  datasetDefaults?: boolean | null;
  within?: RecallWithinEnum | null;
};
export type RecallMultiQuery = {
  queries: RecallQuery[];
};
export type AuRecall = {
  productName?: string | null;
  productNameDescription?: string | null;
  productType?: string | null;
  reasonIssue?: string | null;
  recallAction?: string | null;
  recallActionInstructions?: string | null;
  recallActionLevel?: string | null;
  recallClass?: string | null;
  recallDate?: string | null;
  recallingFirm?: string | null;
  recallNumber?: string | null;
};
export type AuRecallTableResponse = {
  recalls?: AuRecall[] | null;
  took: object;
  totalResults: TotalResults;
};
export type AuRecallFieldEnum =
  | "BASIL_DATE_CREATED"
  | "BRAND_NAME"
  | "ESTABLISHMENT_NAME"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "PRODUCT_CODE"
  | "RECALLING_FIRM"
  | "RECALL_ACTION"
  | "RECALL_CLASS"
  | "RECALL_DATE"
  | "RECALL_NUMBER"
  | "RECALL_REASON";
export type AuRecallFilter = {
  field: AuRecallFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type AuRecallQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: AuRecallFilter[];
  orderBy?: RecallOrderBy[];
};
export type AuRecallMultiQuery = {
  queries: AuRecallQuery[];
};
export type CaRecall = {
  issue?: string | null;
  issueTypes?: string[] | string | null;
  mergedDate?: string | null;
  product?: string[] | string | null;
  recallClass?: string | null;
  recallDate?: string | null;
  recallNumber?: string | null;
  recallNumberId?: string | null;
  recallingFirm?: string[] | string | null;
  title?: string | null;
  typeOfCommunication?: string | null;
};
export type CaRecallTableResponse = {
  recalls?: CaRecall[] | null;
  took: object;
  totalResults: TotalResults;
};
export type CaRecallFieldEnum =
  | "BASIL_DATE_CREATED"
  | "BRAND_NAME"
  | "ESTABLISHMENT_NAME"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "PRODUCT_CODE"
  | "RECALLING_FIRM"
  | "RECALL_CLASS"
  | "RECALL_DATE"
  | "RECALL_NUMBER"
  | "RECALL_REASON";
export type CaRecallFilter = {
  field: CaRecallFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type CaRecallQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: CaRecallFilter[];
  orderBy?: RecallOrderBy[];
};
export type CaRecallMultiQuery = {
  queries: CaRecallQuery[];
};
export type RecallHistogram = {
  applicationNumber?: object | null;
  familyBrand?: object | null;
  familyCompany?: object | null;
  month: number;
  productCode?: object | null;
  recallClass?: object | null;
  recallingFirmName?: object | null;
  year: number;
};
export type RecallHistogramResponse = {
  appliedSearch: any;
  applicationNumber?: string[] | null;
  brandName?: string[] | null;
  companyName?: string[] | null;
  familyBrand?: string[] | null;
  familyCompany?: string[] | null;
  productCode?: string[] | null;
  recallClass?: string[] | null;
  recallingFirmName?: string[] | null;
  monthlyData?: RecallHistogram[] | null;
  took: object;
};
export type RecallHistogramEnum =
  | "APPLICATION_NUMBER"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "FIRM_NAME";
export type RecallHistogramQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: RecallFilter[];
  orderBy?: RecallOrderBy[];
  datasetId?: string | null;
  dataset?: object | null;
  datasetDefaults?: boolean | null;
  within?: RecallWithinEnum | null;
  histogram?: RecallHistogramEnum;
};
export type ReviewPanelPmiCombined = {
  adverseEventCount?: number;
  key: string;
  recallCount?: number;
  recallCountProduct?: number;
  device?: string | null;
};
export type ReviewPanelPmiResponse = {
  reviewPanels: ReviewPanelPmiCombined[];
  totalTime: number;
};
export type ReviewPanelData = {
  key: string;
  count: number;
  countProduct?: number | null;
};
export type ReviewPanelDataCombined = {
  byAdverseEvent?: ReviewPanelPmiCombined[] | null;
  byRecall?: ReviewPanelPmiCombined[] | null;
};
export type ReviewPanelDataMeta = {
  adverseEventStartDate?: string | null;
  adverseEventEndDate?: string | null;
};
export type ReviewPanelDataResponse = {
  deviceProblem: ReviewPanelData[];
  eventType: ReviewPanelData[];
  familyBrand?: ReviewPanelDataCombined | null;
  familyCompany?: ReviewPanelDataCombined | null;
  gmdn?: ReviewPanelDataCombined | null;
  meta?: ReviewPanelDataMeta | null;
  patientProblem: ReviewPanelData[];
  productCode?: ReviewPanelDataCombined | null;
  recallClass: ReviewPanelData[];
  recallReason: ReviewPanelData[];
  reportedOutcome: ReviewPanelData[];
  totalResults: {
    [key: string]: TotalResults;
  };
  totalTime: number;
};
export type ReviewPanelFieldEnum =
  | "APPLICATION_NUMBER"
  | "ESTABLISHMENT_NAME"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "GMDN"
  | "GUDID"
  | "PRODUCT_CODE"
  | "REVIEW_PANEL";
export type ReviewPanelFilter = {
  field: ReviewPanelFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type ReviewPanelDataQuery = {
  dateRange: DateRangeEnum;
  limitTo?: ReviewPanelPmiLimitToEnum | null;
  datasetId?: string | null;
  filters?: ReviewPanelFilter[];
  top?: number | null;
};
export type ReviewPanelRegulatoryCombined = {
  denovoCount?: number;
  device?: string | null;
  fivetenkCount?: number;
  hdeCount?: number;
  hdeSupplementCount?: number;
  key: string;
  pmaCount?: number;
  pmaSupplementCount?: number;
  productCodeCount?: number;
};
export type ReviewPanelRegulatoryTotalCombined = {
  denovoCount?: number;
  fivetenkCount?: number;
  hdeCount?: number;
  hdeSupplementCount?: number;
  pmaCount?: number;
  pmaSupplementCount?: number;
  productCodeCount?: number;
};
export type ReviewPanelRegulatoryResponse = {
  reviewPanels?: ReviewPanelRegulatoryCombined[] | null;
  totals: ReviewPanelRegulatoryTotalCombined;
  totalTime: number;
};
export type ReviewPanelRegulatoryLimitToEnum =
  | "DE_NOVO"
  | "FIVETENK"
  | "HDE"
  | "HDE_SUPPLEMENT"
  | "PMA"
  | "PMA_SUPPLEMENT"
  | "PRODUCT_CODE";
export type ReviewPanelRegulatoryQuery = {
  dateRange: DateRangeEnum;
  limitTo?: ReviewPanelRegulatoryLimitToEnum | null;
};
export type ReviewPanelRegulatoryDevice = {
  applicant?: {
    [key: string]: number;
  } | null;
  familyCompany?: {
    [key: string]: number;
  } | null;
  establishmentName?: {
    [key: string]: number;
  } | null;
  submissionTypes?: {
    [key: string]: number;
  } | null;
  reviewPanel?: {
    [key: string]: number;
  } | null;
};
export type ReviewPanelRegulatoryDataResponse = {
  reviewPanels?: ReviewPanelRegulatoryCombined[] | null;
  totals: ReviewPanelRegulatoryTotalCombined;
  totalTime: number;
  deNovos: ReviewPanelRegulatoryDevice;
  fivetenks: ReviewPanelRegulatoryDevice;
  hdes: ReviewPanelRegulatoryDevice;
  hdeSupplements: ReviewPanelRegulatoryDevice;
  pmas: ReviewPanelRegulatoryDevice;
  pmaSupplements: ReviewPanelRegulatoryDevice;
};
export type ReviewPanelRegulatoryDataQuery = {
  dateRange: DateRangeEnum;
  limitTo?: ReviewPanelRegulatoryLimitToEnum | null;
  filters?: ReviewPanelFilter[];
  top?: number | null;
};
export type TrendDetail = {
  totals?: {
    [key: string]: number;
  } | null;
  values?: string[] | null;
  reviewTimeAverage?: {
    [key: string]: number;
  } | null;
};
export type TrendDate = {
  day?: number | null;
  month: number;
  year: number;
  reviewPanel?: {
    [key: string]: number;
  } | null;
  submissionType?: TrendDetail | null;
  reviewTimeAverage?: number | null;
};
export type Trend = {
  week?: TrendDate[] | null;
  month?: TrendDate[] | null;
  reviewPanel?: TrendDetail | null;
  submissionType?: TrendDetail | null;
};
export type ReviewPanelRegulatoryTrend = {
  fivetenk?: Trend | null;
  deNovo?: Trend | null;
  hde?: Trend | null;
  hdeSupplement?: Trend | null;
  pma?: Trend | null;
  pmaSupplement?: Trend | null;
  productCode?: Trend | null;
};
export type ReviewPanelRegulatoryTrendResponse = {
  trends?: ReviewPanelRegulatoryTrend | null;
};
export type ReviewPanelRegulatoryTrendQuery = {
  dateRange: DateRangeEnum;
  limitTo?: ReviewPanelRegulatoryLimitToEnum | null;
  filters?: ReviewPanelFilter[];
};
export type CountResponse = {
  results: {
    [key: string]: number;
  }[];
  took: object;
};
export type BodyGetBasicCountByApplicationGudidSearchV1BasicCountByApplicationPost =
  {
    applicationNumbers: any[];
  };
export type BodyGetBasicCountByProductCodeGudidSearchV1BasicCountByProductCodePost =
  {
    productCodes: any[];
  };
export type CountLatestPublishDate = {
  count?: number | null;
  latestPublishDate?: string | null;
};
export type CountWithLatestPublishDateResponse = {
  results: {
    [key: string]: CountLatestPublishDate;
  }[];
  took: object;
};
export type GudidFilter = {
  field: FieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type BaseOrderBy = {
  fieldName: string;
  ascending: boolean;
};
export type CountWithInEnum = "BRAND_NAME";
export type CountQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: GudidFilter[];
  orderBy?: BaseOrderBy[];
  applicationNumbers: string[];
  companyNames: string[];
  within?: CountWithInEnum | null;
};
export type Device = {
  applicantName?: string | null;
  applicationNumber?: string | null;
  applicationType?: string | null;
  brandFamily?: string[] | null;
  brandName?: string[] | null;
  companyName?: string | null;
  decisionDate?: string | string | null;
  devicesCount?: number | null;
  latestPublishDate?: string | string | null;
  title?: string | null;
};
export type Facets = {
  applicationType?: TermFacet[] | null;
  brandName?: TermFacet[] | null;
  companyName?: TermFacet[] | null;
  devicePublishDate?: DateFacet | null;
};
export type Item = {
  name: string;
  count: number;
};
export type Family = {
  alphaOrder: number[];
  countOrder: number[];
  items: Item[];
};
export type Families = {
  brandName: Family;
  companyName?: Family | null;
};
export type SearchPageResponse = {
  appliedSearch: any;
  applicationTypes?: string[] | null;
  brandNames?: string[] | null;
  companyNames?: string[] | null;
  dates?: string[] | null;
  devices?: Device[] | null;
  facets: Facets;
  families: Families;
  totalResults: object;
  took: object;
};
export type PageFieldNameEnum =
  | "GUDID_COUNT"
  | "PUBLISH_DATE"
  | "DECISION_DATE";
export type PageOrderBy = {
  fieldName?: PageFieldNameEnum;
  ascending?: boolean;
};
export type SearchWithInEnum = "BRAND_NAME";
export type SearchDeviceQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: GudidFilter[];
  orderBy?: PageOrderBy;
  within?: SearchWithInEnum | null;
};
export type SearchTableResponse = {
  brandNames?: string[] | null;
  catalogNumbers?: string[] | null;
  companyNames?: string[] | null;
  deviceDescriptions?: string[] | null;
  distributionEndDates?: (string | string)[] | null;
  distributionStatuses?: string[] | null;
  publishDates?: string[] | null;
  versionModelNumbers?: string[] | null;
  devices?: any[][] | null;
  took: object;
  totalResults: object;
};
export type TableFieldNameEnum =
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "DEVICE_PUBLISH_DATE";
export type TableOrderBy = {
  fieldName?: TableFieldNameEnum;
  ascending?: boolean;
};
export type SearchTableQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number | null;
  filters?: GudidFilter[];
  orderBy?: TableOrderBy[];
  within?: SearchWithInEnum | null;
};
export type BodyGetCountByApplicationGudidSearchV1CountByApplicationPost = {
  applicationNumbers: any[];
};
export type BodyGetCountByProductCodeGudidSearchV1CountByProductCodePost = {
  productCodes: any[];
};
export type BodyGetPmiCountByProductCodeGudidSearchV1PmiCountByProductCodePost =
  {
    productCodes: any[];
  };
export type SearchKeyResponse = {
  appliedSearch?: object | null;
  devices?: ApplicationDevice[] | null;
  took: object;
  totalResults: object;
};
export type SearchKeyQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number | null;
  filters?: GudidFilter[];
  orderBy: GudidOrderBy[];
  within?: SearchWithInEnum | null;
  recordKeys: string[];
};
export type PmiCountResponse = {
  adverseEventCount?: number | null;
  brandCount?: number | null;
  companyCount?: number | null;
  categoryCount?: number | null;
  recallCount?: number | null;
  totalTime: number;
};
export type BodyGetSearchCountGudidSearchV1PmiSearchCountPost = {
  query: string;
};
export type Histogram = {
  month: number;
  year: number;
  applicationTypes?: object | null;
  brandName?: object | null;
  companyName?: object | null;
};
export type HistogramResponse = {
  appliedSearch: any;
  applicationTypes?: string[] | null;
  brandNames?: string[] | null;
  companyNames?: string[] | null;
  monthlyData?: Histogram[] | null;
  took: object;
};
export type HistogramEnum = "APPLICATION_TYPE" | "BRAND_NAME" | "COMPANY_NAME";
export type HistogramWithInEnum = "BRAND_NAME";
export type HistogramQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters?: GudidFilter[];
  orderBy?: BaseOrderBy[];
  histogram?: HistogramEnum;
  within?: HistogramWithInEnum | null;
};
export type SearchApplicationResponse = {
  appliedSearch?: object | null;
  devices?: ApplicationDevice[] | null;
  took: object;
  totalResults: object;
};
export type SearchApplicationQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number | null;
  filters?: GudidFilter[];
  orderBy: GudidOrderBy[];
  within?: SearchWithInEnum | null;
  applicationNumbers: string[];
};
export type BodyGetBasicCountByApplicationMaudeSearchV1BasicCountByApplicationPost =
  {
    applicationNumbers: any[];
    productCodes: any[];
  };
export type BodyGetBasicCountByProductCodeMaudeSearchV1BasicCountByProductCodePost =
  {
    productCodes: any[];
  };
export type ReviewPanelCount = {
  reviewPanel: string;
  count: number;
};
export type ReviewPanelCountResponse = {
  reviewPanels: ReviewPanelCount[];
  took?: number | null;
};
export type MaudeFieldEnum =
  | "ADVERSE_EVENT_BRAND_NAME"
  | "APPLICATION_NUMBER"
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "CREATED_DATE"
  | "DATE_RECEIVED"
  | "DEVICE_PROBLEM"
  | "DEVICE_PROBLEM_FDA_CODE"
  | "ESTABLISHMENT_NAME"
  | "EVENT_TYPE"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "GMDN"
  | "GUDID"
  | "MANUFACTURER"
  | "PATIENT_PROBLEM"
  | "PATIENT_PROBLEM_FDA_CODE"
  | "PRODUCT_CODE"
  | "REPORT_SOURCE_CODE"
  | "REPORTED_OUTCOME"
  | "REPORT_TYPE"
  | "REVIEW_PANEL"
  | "SINGLE_USE_LABELED"
  | "SINGLE_USE_REPROCESSED";
export type MaudeFilter = {
  field: MaudeFieldEnum;
  values?: string[] | null;
  end?: string | null;
  start?: string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type MaudeWithInEnum =
  | "AE_BRAND_NAME"
  | "APPLICATION_NUMBER"
  | "BRAND_NAME"
  | "COMPANY_NAME"
  | "EVENTS"
  | "DEVICE_PROBLEM"
  | "NARRATIVE"
  | "PATIENT_PROBLEM";
export type MaudeHistogramEnum =
  | "APPLICATION_NUMBER"
  | "DEVICE_PROBLEM"
  | "EVENT_TYPE"
  | "ESTABLISHMENT_NAME"
  | "FAMILY_BRAND"
  | "FAMILY_COMPANY"
  | "GMDN"
  | "MANUFACTURER"
  | "PATIENT_PROBLEM"
  | "PRODUCT_CODE"
  | "REPORT_SOURCE_CODE"
  | "REPORTED_OUTCOME"
  | "REPORT_TYPE"
  | "SINGLE_USE_LABELED"
  | "SINGLE_USE_REPROCESSED";
export type MaudeFieldNameEnum =
  | "APPLICATION_NUMBER"
  | "BRAND_NAME"
  | "EVENT_TYPE"
  | "MANUFACTURER"
  | "PRODUCT_CODE"
  | "RECEIVED_DATE"
  | "RELEVANCE"
  | "REPORT_KEY";
export type MaudeOrderBy = {
  fieldName: MaudeFieldNameEnum;
  ascending: boolean;
};
export type MaudeSearchQuery = {
  filters?: MaudeFilter[];
  query: string[] | string;
  datasetId?: string | null;
  dataset?: object | null;
  within?: MaudeWithInEnum | null;
  histogram?: MaudeHistogramEnum;
  orderBy?: MaudeOrderBy[];
  pageNumber?: number;
  pageSize?: number | null;
  facetFilters?: object | null;
};
export type MaudeHistogram = {
  day?: number | null;
  month?: number | null;
  week?: number | null;
  year: number;
  applicationNumber?: object | null;
  brandName?: object | null;
  companyName?: object | null;
  eventType?: object | null;
  establishmentName?: object | null;
  familyBrand?: object | null;
  familyCompany?: object | null;
  gmdn?: object | null;
  manufacturer?: object | null;
  reportType?: object | null;
  reportedOutcome?: object | null;
  deviceProblem?: object | null;
  patientProblem?: object | null;
  reportSourceCode?: object | null;
  productCode?: object | null;
  singleUseLabeled?: object | null;
  singleUseReprocessed?: object | null;
};
export type MaudeFacets = {
  applicationNumber?: TermFacet[] | null;
  brandName?: TermFacet[] | null;
  companyName?: TermFacet[] | null;
  dateReceived?: DateFacet | null;
  deviceProblem?: TermFacet[] | null;
  establishmentName?: TermFacet[] | null;
  eventType?: TermFacet[] | null;
  familyBrand?: TermFacet[] | null;
  familyCompany?: FamilyCompanyTermFacet[] | null;
  gmdn?: TermFacet[] | null;
  manufacturer?: TermFacet[] | null;
  ownerOperator?: TermFacet[] | null;
  patientProblem?: TermFacet[] | null;
  productCode?: ProductCodeTermFacet[] | null;
  reportSourceCode?: TermFacet[] | null;
  reportType?: TermFacet[] | null;
  reportedOutcome?: TermFacet[] | null;
  singleUseLabeled?: TermFacet[] | null;
  singleUseReprocessed?: TermFacet[] | null;
};
export type MaudeSearchPageResponse = {
  appliedSearch?: any | null;
  applicationNumber?: string[] | null;
  brandName?: string[] | null;
  companyName?: string[] | null;
  eventType?: string[] | null;
  establishmentName?: string[] | null;
  familyBrand?: string[] | null;
  familyCompany?: string[] | null;
  gmdn?: string[] | null;
  manufacturer?: string[] | null;
  deviceProblem?: string[] | null;
  monthlyData?: MaudeHistogram[] | null;
  patientProblem?: string[] | null;
  productCode?: string[] | null;
  reportType?: string[] | null;
  reportSourceCode?: string[] | null;
  reportedOutcome?: string[] | null;
  singleUseLabeled?: string[] | null;
  singleUseReprocessed?: string[] | null;
  sourceAlphaSorted?: number[] | null;
  sourceCountSorted?: number[] | null;
  took: object;
  totalResults: object;
  applications?: string[] | null;
  applicationsMeta?: string[] | null;
  applicationTypes?: string[] | null;
  brandNames?: string[] | null;
  dates?: string[] | null;
  events?: any[][] | null;
  withinCounts?: object | null;
  facets: MaudeFacets;
};
export type MaudeTableResponse = {
  applications?: string[] | null;
  applicationsMeta?: string[] | null;
  applicationTypes?: string[] | null;
  brandNames?: string[] | null;
  dates?: string[] | null;
  eventTypes?: string[] | null;
  events?: any[][] | null;
  manufacturers?: string[] | null;
  took: object;
  totalResults: object;
};
export type MaudeTableQuery = {
  filters?: MaudeFilter[];
  query: string[] | string;
  datasetId?: string | null;
  dataset?: object | null;
  within?: MaudeWithInEnum | null;
  orderBy?: MaudeOrderBy[];
  pageNumber?: number;
  pageSize?: number | null;
};
export type BodyGetCountByApplicationMaudeSearchV1CountByApplicationPost = {
  applicationNumbers: any[];
  productCodes: any[];
};
export type BodyGetCountByProductCodeMaudeSearchV1CountByProductCodePost = {
  productCodes: any[];
};
export type MaudePmiResponse = {
  facets: MaudeFacets;
  totalResults: object;
  withinCounts?: object | null;
};
export type MaudeTableMultiQuery = {
  queries: MaudeTableQuery[];
};
export type AuAdverseEvent = {
  eventType?: string[] | string | null;
  manufacturers?: string[] | string | null;
  brands?: string[] | string | null;
  reportDate?: string | null;
  reportNumber?: string | null;
};
export type AuMaudeTableResponse = {
  took: object;
  totalResults: object;
  adverseEvents?: AuAdverseEvent[] | null;
};
export type AuMaudeFieldEnum =
  | "BRAND_NAME"
  | "DATE_RECEIVED"
  | "EVENT_TYPE"
  | "GMDN"
  | "MANUFACTURER"
  | "PRODUCT_CODE"
  | "REPORTED_EVENT_OUTCOME";
export type AuMaudeFilter = {
  field: AuMaudeFieldEnum;
  values?: string[] | null;
  end?: string | null;
  start?: string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type AuMaudeFieldNameEnum =
  | "BRAND_NAME"
  | "EVENT_TYPE"
  | "MANUFACTURER"
  | "RECEIVED_DATE"
  | "RELEVANCE"
  | "REPORT_NUMBER";
export type AuMaudeOrderBy = {
  fieldName: AuMaudeFieldNameEnum;
  ascending: boolean;
};
export type AuMaudeTableQuery = {
  filters?: AuMaudeFilter[];
  query?: string | null;
  datasetId?: string | null;
  dataset?: object | null;
  pageNumber?: number;
  pageSize?: number | null;
  orderBy?: AuMaudeOrderBy[];
};
export type AuMaudeTableMultiQuery = {
  queries: AuMaudeTableQuery[];
};
export type CaAdverseEvent = {
  eventType?: string | null;
  manufacturer?: string[] | string | null;
  brands?: string[] | string | null;
  reportDate?: string | null;
  reportNumber?: string | null;
};
export type CaMaudeTableResponse = {
  took: object;
  totalResults: object;
  adverseEvents?: CaAdverseEvent[] | null;
};
export type CaMaudeFieldEnum =
  | "BRAND_NAME"
  | "DATE_RECEIVED"
  | "MANUFACTURER"
  | "PATIENT_DEVICE";
export type CaMaudeFilter = {
  field: CaMaudeFieldEnum;
  values?: string[] | null;
  end?: string | null;
  start?: string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type CaMaudeFieldNameEnum =
  | "BRAND_NAME"
  | "EVENT_TYPE"
  | "MANUFACTURER"
  | "RECEIVED_DATE"
  | "RELEVANCE"
  | "REPORT_NUMBER";
export type CaMaudeOrderBy = {
  fieldName: CaMaudeFieldNameEnum;
  ascending: boolean;
};
export type CaMaudeTableQuery = {
  filters?: CaMaudeFilter[];
  query?: string | null;
  datasetId?: string | null;
  dataset?: object | null;
  pageNumber?: number;
  pageSize?: number | null;
  orderBy?: CaMaudeOrderBy[];
};
export type CaMaudeTableMultiQuery = {
  queries: CaMaudeTableQuery[];
};
export type ApplicationResponse = {
  applicationNumbers: string[];
  totalTime: number;
};
export type DeviceResponse = {
  deviceKeys: string[];
  totalTime: number;
};
export type MaudeHistogramResponse = {
  appliedSearch?: any | null;
  applicationNumber?: string[] | null;
  brandName?: string[] | null;
  companyName?: string[] | null;
  eventType?: string[] | null;
  establishmentName?: string[] | null;
  familyBrand?: string[] | null;
  familyCompany?: string[] | null;
  gmdn?: string[] | null;
  manufacturer?: string[] | null;
  deviceProblem?: string[] | null;
  monthlyData?: MaudeHistogram[] | null;
  patientProblem?: string[] | null;
  productCode?: string[] | null;
  reportType?: string[] | null;
  reportSourceCode?: string[] | null;
  reportedOutcome?: string[] | null;
  singleUseLabeled?: string[] | null;
  singleUseReprocessed?: string[] | null;
  sourceAlphaSorted?: number[] | null;
  sourceCountSorted?: number[] | null;
  took: object;
  totalResults: object;
};
export type MaudeHistogramQuery = {
  filters?: MaudeFilter[];
  query: string[] | string;
  datasetId?: string | null;
  dataset?: object | null;
  within?: MaudeWithInEnum | null;
  histogram?: MaudeHistogramEnum;
};
export type MaudeCountResponse = {
  appliedSearch: any;
  count: number;
  took: object;
};
export type MaudeMultiCountResponse = {
  counts: {
    [key: string]: number;
  };
  took: number;
};
export type MaudeSearchQueryWithKey = {
  filters?: MaudeFilter[];
  query?: string | null;
  datasetId?: string | null;
  dataset?: object | null;
  within?: MaudeWithInEnum | null;
  histogram?: MaudeHistogramEnum;
  orderBy?: MaudeOrderBy[];
  pageNumber?: number;
  pageSize?: number | null;
  facetFilters?: object | null;
  key: string;
};
export type MaudeMultiSearchQuery = {
  queries: MaudeSearchQueryWithKey[];
};
export type UsMaudeBrandMatrix = {
  problem?: string | null;
  fdaCode: string;
  level1Term?: string | null;
  level2Term?: string | null;
  level3Term?: string | null;
  count: number;
};
export type UsMaudeBrandEventTypeMatrix = {
  name: string;
  count: number;
};
export type UsMaudeBrandMatrixResults = {
  adverseEventCount: object;
  deviceProblemCount?: number | null;
  deviceProblem?: UsMaudeBrandMatrix[] | null;
  patientProblemCount?: number | null;
  patientProblem?: UsMaudeBrandMatrix[] | null;
  eventTypeCount?: number | null;
  eventType?: UsMaudeBrandEventTypeMatrix[] | null;
};
export type UsMaudeBrandMatrixResponse = {
  results: {
    [key: string]: UsMaudeBrandMatrixResults;
  };
  facets: MaudeFacets;
  took?: number | null;
};
export type MaudeBrandQueryLimitToEnum = "DEVICE" | "PATIENT" | "EVENT_TYPE";
export type MaudeBrandQuery = {
  filters?: MaudeFilter[];
  query: string;
  key?: string | null;
  limitTo?: MaudeBrandQueryLimitToEnum | null;
};
export type MaudeReviewPanelDataResponse = {
  applicationNumber: ReviewPanelData[];
  deviceProblem: ReviewPanelData[];
  eventType: ReviewPanelData[];
  familyBrand: ReviewPanelData[];
  familyCompany: ReviewPanelData[];
  gmdn: ReviewPanelData[];
  patientProblem: ReviewPanelData[];
  productCode: ReviewPanelData[];
  reportedOutcome: ReviewPanelData[];
  totalResults: TotalResults;
  totalTime?: number | null;
};
export type MaudeReviewPanelQuery = {
  filters?: MaudeFilter[];
  query?: string | null;
  reviewPanel?: string | null;
  size?: number | null;
};
export type RegulationFieldEnum = "BASIL_DATE_CREATED";
export type RegulationFilter = {
  field: RegulationFieldEnum;
  values?: (string | boolean)[] | null;
  end?: number | string | null;
  start?: number | string | null;
  operator?: OperatorEnum | null;
  period?: PeriodEnum | null;
  searchType?: SearchTypeEnum | null;
  datasetDerived?: boolean | null;
};
export type SearchRegulationQuery = {
  query?: string | null;
  pageNumber?: number;
  pageSize?: number;
  filters: RegulationFilter[];
  orderBy?: BaseOrderBy[];
};
export type Contact = {
  phone?: string | null;
  phoneExtension?: number | string | null;
  email?: string | null;
};
export type StorageHandling = {
  unit?: string | null;
  value?: number | string | null;
};
export type EnvironmentalCondition = {
  storageHandlingHigh: StorageHandling;
  storageHandlingLow: StorageHandling;
  storageHandlingSpecialConditionText?: string | null;
  storageHandlingType?: string | null;
};
export type GmdnTerms = {
  gmdnPtname: string;
  gmdnPtdefinition: string;
};
export type Identifier = {
  deviceId?: number | string | null;
  deviceIdType?: string | null;
  deviceIdIssuingAgency?: string | null;
  containsDinumber?: string | null;
  pkgQuantity?: number | null;
  pkgDiscontinueDate?: string | null;
  pkgStatus?: string | null;
  pkgType?: string | null;
};
export type PremarketSubmission = {
  submissionNumber?: string | null;
  submissionType?: string | null;
  supplementNumber?: number | null;
};
export type ProductCode = {
  code: string;
  definition?: string | null;
};
export type Sterilization = {
  deviceSterile?: boolean | null;
  sterilizationPriorToUse?: boolean | null;
  methodTypes?: string[] | null;
};
export type Gudid = {
  _id?: string;
  brandName?: string | null;
  catalogNumber?: string | null;
  companyName?: string | null;
  contacts?: Contact[] | null;
  deviceCombinationProduct?: boolean | null;
  deviceCommDistributionEndDate?: string | null;
  deviceCommDistributionStatus?: string | null;
  deviceCount?: number | null;
  deviceDescription?: string | null;
  deviceHctp?: boolean | null;
  deviceKit?: boolean | null;
  devicePublishDate?: string | null;
  deviceRecordStatus?: string | null;
  deviceSizes?: object[] | string | null;
  dmexempt?: boolean | null;
  donationIdNumber?: boolean | null;
  dunsNumber?: number | null;
  environmentalConditions?: EnvironmentalCondition[] | null;
  expirationDate?: boolean | null;
  gmdnTerms?: GmdnTerms[] | null;
  identifiers?: Identifier[] | null;
  labeledContainsNrl?: boolean | null;
  labeledNoNrl?: boolean | null;
  lotBatch?: boolean | null;
  manufacturingDate?: boolean | null;
  mrisafetyStatus?: string | null;
  otc?: boolean | null;
  premarketExempt?: boolean | null;
  premarketSubmissions?: PremarketSubmission[] | null;
  productCodes?: ProductCode[] | null;
  publicDeviceRecordKey: string;
  publicVersionDate?: string | null;
  publicVersionNumber?: number | null;
  publicVersionStatus?: string | null;
  rx?: boolean | null;
  serialNumber?: boolean | null;
  singleUse?: boolean | null;
  sterilization?: Sterilization | null;
  versionModelNumber?: string | null;
};
export type Keys = {
  deviceRecordKeys: string[];
};
export type BodyGetDetailByApplicationNumberGudidDataV1BasicGudidByApplicationNumberPost =
  {
    applicationNumbers: string[];
  };
export type FamilyBrandNames = {
  familyBrandNames: string[];
};
export type FamilyCompanyNames = {
  familyCompanyNames: string[];
};
export type BrandNames = {
  brandNames: string[];
};
export type CompanyNames = {
  companyNames: string[];
};
export type Family2 = {
  key: string;
  name: string;
  terms: string[];
};
export type FamilyCompanyResponse = {
  families: Family2[];
};
export type BodyGetByFamilyCompanyIdGudidDataV1FamilyByFamilyCompanyIdPost = {
  familyCompanyIds: string[];
};
export type FamilyBrandResponse = {
  families: Family2[];
};
export type BodyGetByFamilyBrandIdGudidDataV1FamilyByFamilyBrandIdPost = {
  familyBrandIds: string[];
};
export type Gmdn = {
  term: string;
  definition: string;
};
export type BodyGetByTermGudidDataV1GmdnByTermPost = {
  gmdnTerms: string[];
};
export type BodyGetDetailByApplicationNumberGudidDataV1GudidByApplicationNumberPost =
  {
    applicationNumbers: string[];
  };
export type BodyPmiGetByFamilyBrandIdGudidDataV1PmiFamilyByFamilyBrandIdPost = {
  familyBrandIds: string[];
};
export type ProductCodeSummary = {
  productCode: string;
  device: string;
  deviceClass: string;
  reviewPanel: string;
  regulationMedicalSpecialty: string;
};
export type BasicRegulation = {
  _id: string;
  applicants510k?: string[] | null;
  applicantsPma?: string[] | null;
  count510k?: number | null;
  countPma?: number | null;
  countPmasNonSupplemental?: number | null;
  deviceClass?: string | string[] | null;
  deviceEventCount?: number | null;
  identification?: string | string[] | null;
  ids510k?: string[] | null;
  idsPma?: string[] | null;
  isProductCode?: string[] | null;
  link: string;
  name?: string | null;
  names510k?: string[] | null;
  namesPma?: string[] | null;
  namesProductCode?: string[] | null;
  numApplications?: number | null;
  part: string;
  productCodes?: ProductCodeSummary[] | null;
  recallCount?: number | null;
  regulationNumber: string;
  subpart: string;
  type?: string | null;
};
export type RegulationQuery = {
  fields?: string[] | null;
  regulationNumbers: string[];
};
export type Regulation = {
  _id: string;
  applicants510k?: string[] | null;
  applicantsPma?: string[] | null;
  count510k?: number | null;
  countPma?: number | null;
  countPmasNonSupplemental?: number | null;
  deviceClass?: string | string[] | null;
  deviceEventCount?: number | null;
  identification?: string | string[] | null;
  ids510k?: string[] | null;
  idsPma?: string[] | null;
  isProductCode?: string[] | null;
  link: string;
  name?: string | null;
  names510k?: string[] | null;
  namesPma?: string[] | null;
  namesProductCode?: string[] | null;
  numApplications?: number | null;
  part: string;
  productCodes?: ProductCodeSummary[] | null;
  recallCount?: number | null;
  regulationNumber: string;
  subpart: string;
  type?: string | null;
};
export type StatsResponse = {
  id: string;
  remoteIp?: string | null;
};
export type Stat = {
  fingerprint: string;
};
export type FamilyTypeEnum = "ESTABLISHMENT" | "OTHER" | "OWNER";
export type FamilyCompany = {
  key: string;
  name: string;
  companies?: string[] | null;
  familyType?: FamilyTypeEnum | null;
};
export type Brand = {
  query: string;
  adverseEventBrands?: string[] | null;
  recallBrands?: string[] | null;
};
export type PmiDatasetTypeEnum =
  | "BRANDS"
  | "CATEGORIES"
  | "COMPANIES"
  | "REVIEW_PANEL";
export type FamilyBrand = {
  key: string;
  name: string;
  brands?: string[] | null;
};
export type DatasetRecord = {
  name: string;
  query?: string | null;
  familyCompanies?: FamilyCompany[] | null;
  brands?: Brand[] | null;
  dashboard?: boolean | null;
  datasetType: PmiDatasetTypeEnum;
  familyBrands?: FamilyBrand[] | null;
  filters?: object[] | null;
  gmdns?: string[] | null;
  productCodes?: string[] | null;
  reviewPanels?: string[] | null;
  within?: string | null;
  _id?: string;
  ownerId: string;
  dateCreated: string;
  dateModified: string;
  dateLastViewed?: string | null;
  hasFilters?: boolean;
  completeQuery?: object | null;
};
export type DeviceItem = {
  docType: string;
  itemId: string;
  title?: string | null;
};
export type SavedDataResponse = {
  datasets: DatasetRecord[];
  savedItems: DeviceItem[];
};
export const {
  useSearchClinicalTrialQuery,
  useSearchGuidanceQuery,
  useSearchPmiQuery,
  useSearchPmiCountQuery,
  useGetGudidTableQuery,
  useGetPmiDatasetListQuery,
  useRecallTableQuery,
  useRecallTableMultiQuery,
  useAuRecallTableQuery,
  useAuRecallTableMultiQuery,
  useCaRecallTableQuery,
  useCaRecallTableMultiQuery,
  useRecallHistogramQuery,
  useGetReviewPanelQuery,
  useGetReviewPanelDataQuery,
  useGetRegulatoryReviewPanelQuery,
  useGetRegulatoryReviewPanelDataQuery,
  useGetRegulatoryReviewPanelTrendQuery,
  useGetBasicRegulatoryReviewPanelQuery,
  useGetBasicRegulatoryReviewPanelDataQuery,
  useGetBasicRegulatoryReviewPanelTrendQuery,
  useGetBasicCountByApplicationQuery,
  useGetBasicCountByProductCodeQuery,
  useGetBassicOuntLatestPublishDateQuery,
  useSearchBasicSearchPageQuery,
  useBasicSearchTableQuery,
  useGetCountByApplicationQuery,
  useGetCountByProductCodeQuery,
  useGetCountLatestPublishDateQuery,
  useGetPmiCountByProductCodeQuery,
  usePmiSearchKeyQuery,
  useGetSearchCountQuery,
  useGudidSearchPageQuery,
  useGudidSearchTableQuery,
  useSearchHistogramQuery,
  useSearchApplicationQuery,
  useSearchKeyQuery,
  useSearchGetBasicCountByApplicationQuery,
  useSearchGetBasicCountByProductCodeQuery,
  useGetBasicReviewPanelByDateQuery,
  useBasicSearchQuery,
  useSearchBasicSearchTableQuery,
  useSearchGetCountByApplicationQuery,
  useSearchGetCountByProductCodeQuery,
  useGetReviewPanelByDateQuery,
  useSearchQuery,
  useGetSearchQuery,
  useSearchSearchTableQuery,
  useSearchTableMultiQuery,
  useAuSearchTableQuery,
  useAuSearchTableMultiQuery,
  useCaSearchTableQuery,
  useCaSearchTableMultiQuery,
  useGetApplicationQuery,
  useGetDeviceQuery,
  usePmiHistogramQuery,
  useSearchCountQuery,
  useSearchCountMultiQuery,
  useSearchSearchBrandQuery,
  useSearchGetReviewPanelDataQuery,
  useGetPmiReviewPanelByDateQuery,
  useSearchSearchQuery,
  useSearchGetSearchQuery,
  useSearchTable1Query,
  useSearchSearchTableMultiQuery,
  useSearchAuSearchTableQuery,
  useSearchAuSearchTableMultiQuery,
  useSearchCaSearchTableQuery,
  useSearchCaSearchTableMultiQuery,
  useSearchGetApplicationQuery,
  useSearchGetDeviceQuery,
  useHistogram1Query,
  useSearchSearchCountQuery,
  useSearchSearchCountMultiQuery,
  useSearchBrand1Query,
  useGetViewableFileQuery,
  useGetDownloadQuery,
  useRegulationCountQuery,
  useGetDetailQuery,
  useGetDetailByApplicationNumberQuery,
  useGetBrandListQuery,
  useDataGetCompanyListQuery,
  useGetByBrandNameQuery,
  useGetByCompanyNameQuery,
  useGetByFamilyCompanyIdQuery,
  useGetByFamilyBrandIdQuery,
  useGetByTermQuery,
  useDataGetDetailQuery,
  useDataGetDetailByApplicationNumberQuery,
  usePmiGetByFamilyBrandIdQuery,
  useGetPmiDetailQuery,
  useDataPostBasicRegulationListQuery,
  useDataGetBasicRegulationDetailsQuery,
  useDataPostRegulationListQuery,
  useDataGetRegulationDetailsQuery,
  useAddStatsMutation,
  useTenantSettingsQuery,
  usePmiGetSavedDataForDashboardQuery,
} = injectedRtkApi;
