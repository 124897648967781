import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    sales: build.mutation<SalesApiResponse, SalesApiArg>({
      query: () => ({ url: `/iam/v1/contact/sales`, method: "POST" }),
    }),
    deleteDomain: build.mutation<DeleteDomainApiResponse, DeleteDomainApiArg>({
      query: () => ({ url: `/iam/v1/domain`, method: "DELETE" }),
    }),
    getDomain: build.query<GetDomainApiResponse, GetDomainApiArg>({
      query: () => ({ url: `/iam/v1/domain` }),
    }),
    domain: build.mutation<DomainApiResponse, DomainApiArg>({
      query: () => ({ url: `/iam/v1/domain`, method: "POST" }),
    }),
    getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
      query: () => ({ url: `/iam/v1/roles` }),
    }),
    getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
      query: (queryArg) => ({ url: `/iam/v1/roles/${queryArg}` }),
    }),
    getCallback: build.query<GetCallbackApiResponse, GetCallbackApiArg>({
      query: () => ({ url: `/iam/v1/sso/saml2/callback` }),
    }),
    callback: build.mutation<CallbackApiResponse, CallbackApiArg>({
      query: () => ({ url: `/iam/v1/sso/saml2/callback`, method: "POST" }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users`,
        params: {
          query: queryArg.query,
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          includeDeleted: queryArg.includeDeleted,
          status: queryArg.status,
        },
      }),
    }),
    acceptInvitation: build.mutation<
      AcceptInvitationApiResponse,
      AcceptInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/accept-invitation`,
        method: "POST",
        body: queryArg,
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/auth/by-email-and-password`,
        method: "POST",
        body: queryArg,
      }),
    }),
    bySingleUseToken: build.mutation<
      BySingleUseTokenApiResponse,
      BySingleUseTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/auth/by-single-use-token`,
        method: "POST",
        body: queryArg,
      }),
    }),
    refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/auth/refresh-token`,
        method: "POST",
        body: queryArg,
      }),
    }),
    byJwt: build.mutation<ByJwtApiResponse, ByJwtApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/by-jwt`,
        method: "POST",
        body: queryArg,
      }),
    }),
    changeStatus: build.mutation<ChangeStatusApiResponse, ChangeStatusApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/change-status`,
        method: "POST",
        body: queryArg,
      }),
    }),
    inviteUser: build.mutation<InviteUserApiResponse, InviteUserApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/invite`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getValidate: build.query<GetValidateApiResponse, GetValidateApiArg>({
      query: () => ({ url: `/iam/v1/users/jwt/validate` }),
    }),
    requestOwnPasswordReset: build.mutation<
      RequestOwnPasswordResetApiResponse,
      RequestOwnPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/request-own-password-reset`,
        method: "POST",
        body: queryArg,
      }),
    }),
    requestPasswordReset: build.mutation<
      RequestPasswordResetApiResponse,
      RequestPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/request-password-reset`,
        method: "POST",
        body: queryArg,
      }),
    }),
    resendInvitation: build.mutation<
      ResendInvitationApiResponse,
      ResendInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/resend-invitation`,
        method: "POST",
        body: queryArg,
      }),
    }),
    resendSignupInvitation: build.mutation<
      ResendSignupInvitationApiResponse,
      ResendSignupInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/resend-signup-invitation`,
        method: "POST",
        body: queryArg,
      }),
    }),
    resetPassword: build.mutation<
      ResetPasswordApiResponse,
      ResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/reset-password`,
        method: "POST",
        body: queryArg,
      }),
    }),
    resetTrial: build.mutation<ResetTrialApiResponse, ResetTrialApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/reset-trial`,
        method: "POST",
        body: queryArg,
      }),
    }),
    signup: build.mutation<SignupApiResponse, SignupApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/signup`,
        method: "POST",
        body: queryArg,
      }),
    }),
    patchSubscription: build.mutation<
      PatchSubscriptionApiResponse,
      PatchSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/subscription`,
        method: "PATCH",
        body: queryArg,
      }),
    }),
    subscription: build.mutation<SubscriptionApiResponse, SubscriptionApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/subscription`,
        method: "POST",
        body: queryArg,
      }),
    }),
    cancel: build.mutation<CancelApiResponse, CancelApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/subscription/cancel`,
        method: "POST",
        body: queryArg,
      }),
    }),
    tosAccept: build.mutation<TosAcceptApiResponse, TosAcceptApiArg>({
      query: () => ({ url: `/iam/v1/users/tos-accept`, method: "POST" }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/iam/v1/users/${queryArg}` }),
    }),
    patchUser: build.mutation<PatchUserApiResponse, PatchUserApiArg>({
      query: (queryArg) => ({
        url: `/iam/v1/users/${queryArg.userId}`,
        method: "PATCH",
        body: queryArg.userUpdates,
      }),
    }),
    getInviteUser: build.query<GetInviteUserApiResponse, GetInviteUserApiArg>({
      query: (queryArg) => ({ url: `/iam/v1/users/${queryArg}/link/invite` }),
    }),
    getPasswordReset: build.query<
      GetPasswordResetApiResponse,
      GetPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/iam/v1/users/${queryArg}/link/password-reset`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type SalesApiResponse = /** status 200 OK */ ContactResult;
export type SalesApiArg = void;
export type DeleteDomainApiResponse = unknown;
export type DeleteDomainApiArg = void;
export type GetDomainApiResponse = /** status 200 OK */ DomainQueryResult;
export type GetDomainApiArg = void;
export type DomainApiResponse = /** status 200 OK */ DomainResult;
export type DomainApiArg = void;
export type GetRolesApiResponse = /** status 200 OK */ RoleQueryResult;
export type GetRolesApiArg = void;
export type GetRoleApiResponse = /** status 200 OK */ Role;
export type GetRoleApiArg = /** Id of role */ string;
export type GetCallbackApiResponse = unknown;
export type GetCallbackApiArg = void;
export type CallbackApiResponse = unknown;
export type CallbackApiArg = void;
export type GetUsersApiResponse = /** status 200 OK */ UserQueryResult;
export type GetUsersApiArg = {
  /** Query string */
  query?: string;
  /** Number of users per page */
  pageSize?: number;
  /** Page number */
  pageNumber?: number;
  /** Include deleted users */
  includeDeleted?: boolean;
  /** Users with specific status */
  status?: string;
};
export type AcceptInvitationApiResponse =
  /** status 200 OK */ UserAcceptInvitationResult;
export type AcceptInvitationApiArg =
  /** User accept invitation parameters */ UserAcceptInvitationRequest;
export type LoginApiResponse = /** status 200 OK */ UserAuthResult;
export type LoginApiArg =
  /** Email address and password parameters */ UserAuthByEmailAndPassword;
export type BySingleUseTokenApiResponse = /** status 200 OK */ UserAuthResult;
export type BySingleUseTokenApiArg =
  /** Token and device parameters */ UserAuthBySingleUseToken;
export type RefreshTokenApiResponse = /** status 200 OK */ RefreshTokenResult;
export type RefreshTokenApiArg =
  /** Access and refresh token */ RefreshTokenRequest;
export type ByJwtApiResponse = /** status 200 OK */ User;
export type ByJwtApiArg = /** JWT of user */ string;
export type ChangeStatusApiResponse =
  /** status 200 OK */ UserChangeStatusResult;
export type ChangeStatusApiArg =
  /** User status change parameters */ UserChangeStatusRequest;
export type InviteUserApiResponse = /** status 200 OK */ UserInvitationResult;
export type InviteUserApiArg =
  /** Invite user parameters */ UserInvitationRequest;
export type GetValidateApiResponse = unknown;
export type GetValidateApiArg = void;
export type RequestOwnPasswordResetApiResponse =
  /** status 200 OK */ UserRequestPasswordResetResult;
export type RequestOwnPasswordResetApiArg =
  /** Email address */ UserRequestOwnPasswordResetRequest;
export type RequestPasswordResetApiResponse =
  /** status 200 OK */ UserRequestPasswordResetResult;
export type RequestPasswordResetApiArg =
  /** Id of user */ UserRequestPasswordResetRequest;
export type ResendInvitationApiResponse =
  /** status 200 OK */ UserResendInvitationResult;
export type ResendInvitationApiArg =
  /** Id of user */ UserResendInvitationRequest;
export type ResendSignupInvitationApiResponse =
  /** status 200 OK */ UserResendInvitationResult;
export type ResendSignupInvitationApiArg =
  /** Email address of user */ UserResendSignupInvitationRequest;
export type ResetPasswordApiResponse =
  /** status 200 OK */ UserResetPasswordResult;
export type ResetPasswordApiArg =
  /** Reset password parameters */ UserResetPasswordRequest;
export type ResetTrialApiResponse = /** status 200 OK */ ResetTrialResult;
export type ResetTrialApiArg =
  /** userId of user to reset trial for */ ResetTrialRequest;
export type SignupApiResponse = /** status 200 OK */ UserSignupResult;
export type SignupApiArg = /** Signup user parameters */ UserSignupRequest;
export type PatchSubscriptionApiResponse =
  /** status 200 OK */ UserSignupResult;
export type PatchSubscriptionApiArg =
  /** User signup parameters */ UserSignupRequest;
export type SubscriptionApiResponse = /** status 200 OK */ UserSignupResult;
export type SubscriptionApiArg =
  /** User signup parameters */ UserSignupRequest;
export type CancelApiResponse = /** status 200 OK */ UserSignupResult;
export type CancelApiArg = /** User signup parameters */ UserSignupRequest;
export type TosAcceptApiResponse = /** status 200 OK */ TosAcceptResult;
export type TosAcceptApiArg = void;
export type GetUserApiResponse = /** status 200 OK */ User;
export type GetUserApiArg = /** Id of user */ string;
export type PatchUserApiResponse = /** status 200 OK */ UserUpdateResult;
export type PatchUserApiArg = {
  /** Id of user */
  userId: string;
  /** User name and Roles */
  userUpdates: UserUpdates;
};
export type GetInviteUserApiResponse = /** status 200 OK */ UserLinkResult;
export type GetInviteUserApiArg = /** Id of user */ string;
export type GetPasswordResetApiResponse = /** status 200 OK */ UserLinkResult;
export type GetPasswordResetApiArg = /** Id of user */ string;
export type ContactResult = {
  errors?: string[];
  status?: string;
};
export type Domain = {
  domainName?: string;
  isAllowed?: boolean;
};
export type DomainQueryResult = {
  errors?: string[];
  matches?: Domain[];
  status?: string;
  totalMatching?: number;
  totalPages?: number;
};
export type DomainResult = {
  domainCount?: number;
  errors?: string[];
  status?: string;
};
export type Product = {
  description?: string;
  id?: string;
  name?: string;
  textId?: string;
};
export type Role = {
  description?: string;
  id?: string;
  linkedProducts?: Product[];
  name?: string;
  status?: string;
  type?: string;
};
export type RoleQueryResult = {
  matches?: Role[];
  totalMatching?: number;
  totalPages?: number;
};
export type UserSsoCallbackResult = {
  errors?: string[];
  status?: string;
  token?: string;
};
export type UserEmailAddress = {
  address?: string;
  createdDatetime?: string;
  id?: string;
  status?: string;
  trialExpired?: boolean;
  type?: string;
};
export type VerificationToken = {
  createdDatetime?: string;
  expirationDatetime?: string;
  failedDatetime?: string;
  id?: string;
  invalidatedDatetime?: string;
  invalidatingUser?: User;
  invalidationReason?: string;
  status?: string;
  verifiedDatetime?: string;
};
export type UserInvitation = {
  id?: string;
  invitedUser?: User;
  invitingUser?: User;
  verificationToken?: VerificationToken;
};
export type Organization = {
  assignedRoles?: Role[];
  childOrganizations?: Organization[];
  description?: string;
  id?: string;
  name?: string;
  parentOrganization?: Organization;
  status?: string;
  type?: string;
};
export type User = {
  assignedRoles?: Role[];
  authType?: string;
  company?: string;
  companyType?: string;
  emailAddresses?: UserEmailAddress[];
  firstName?: string;
  id?: string;
  inheritedRoles?: Role[];
  invitation?: UserInvitation;
  jobRoles?: string[];
  lastAuthDatetime?: string;
  lastName?: string;
  lastSeenDatetime?: string;
  organization?: Organization;
  organizationHierarchyString?: string;
  prevAuthDatetime?: string;
  status?: string;
  title?: string;
  tosAcceptDatetime?: string;
  userType?: string;
  // Manually added - JSON file needs regen
  limitModulePharma?: boolean;
};
export type UserQueryResult = {
  errors?: string[];
  matches?: User[];
  status?: string;
  totalMatching?: number;
  totalPages?: number;
};
export type UserAcceptInvitationResult = {
  errors?: string[];
  invitedUser?: User;
  invitingUser?: User;
  status?: string;
};
export type UserAcceptInvitationRequest = {
  acceptedTos?: string;
  password?: string;
  verificationToken?: string;
  verificationTokenId?: string;
};
export type UserAuthResult = {
  bearerToken?: string;
  bearerTokenRefresh?: string;
  errors?: string[];
  status?: string;
  tokenExpiration?: string;
  tokenRefreshExpiration?: string;
  user?: User;
};
export type UserAuthByEmailAndPassword = {
  device?: string;
  emailAddress?: string;
  password?: string;
};
export type UserAuthBySingleUseToken = {
  device?: string;
  token?: string;
};
export type RefreshTokenResult = {
  bearerToken?: string;
  bearerTokenRefresh?: string;
  errors?: string[];
  status?: string;
  tokenExpiration?: string;
  tokenRefreshExpiration?: string;
  user?: User;
};
export type RefreshTokenRequest = {
  bearerToken?: string;
  bearerTokenRefresh?: string;
  device?: string;
};
export type UserChangeStatusResult = {
  changedUser?: User;
  errors?: string[];
  status?: string;
};
export type UserChangeStatusRequest = {
  newStatus?: string;
  userId?: string;
};
export type UserInvitationResult = {
  errors?: string[];
  status?: string;
  user?: User;
};
export type UserInvitationRequest = {
  assignedRoleIds?: string[];
  firstName?: string;
  lastName?: string;
  organizationId?: string;
  primaryEmailAddress?: string;
};
export type PasswordResetRequest = object;
export type UserRequestPasswordResetResult = {
  errors?: string[];
  initiatingUser?: User;
  passwordResetRequest?: PasswordResetRequest;
  resetUser?: User;
  status?: string;
};
export type UserRequestOwnPasswordResetRequest = {
  emailAddress?: string;
};
export type UserRequestPasswordResetRequest = {
  userId?: string;
};
export type UserResendInvitationResult = {
  errors?: string[];
  status?: string;
  user?: User;
};
export type UserResendInvitationRequest = {
  userId?: string;
};
export type UserResendSignupInvitationRequest = {
  emailAddress?: string;
};
export type UserResetPasswordResult = {
  errors?: string[];
  initiatingUser?: User;
  resetUser?: User;
  status?: string;
};
export type UserResetPasswordRequest = {
  newPassword?: string;
  oldPassword?: string;
  password?: string;
  verificationToken?: string;
  verificationTokenId?: string;
};
export type ResetTrialResult = {
  errors?: string[];
  status?: string;
};
export type ResetTrialRequest = {
  userId?: string;
};
export type UserSignupResult = {
  errors?: string[];
  status?: string;
};
export type UserSignupRequest = {
  company?: string;
  companyType?: string;
  emailAddress?: string;
  firstName?: string;
  jobRoles?: string[];
  lastName?: string;
  message?: string;
  organizationName?: string;
  organizationStripeId?: string;
  recaptcha?: string;
  title?: string;
};
export type TosAcceptResult = {
  errors?: string[];
  status?: string;
  updatedUser?: User;
};
export type UserUpdateResult = {
  errors?: string[];
  status?: string;
  updatedUser?: User;
};
export type UserUpdates = {
  assignedRoleIds?: string[];
  assignedRoles?: Role[];
  firstName?: string;
  lastName?: string;
};
export type UserLinkResult = {
  errors?: string[];
  link?: string;
  status?: string;
};
export const {
  useSalesMutation,
  useDeleteDomainMutation,
  useGetDomainQuery,
  useDomainMutation,
  useGetRolesQuery,
  useGetRoleQuery,
  useGetCallbackQuery,
  useCallbackMutation,
  useGetUsersQuery,
  useAcceptInvitationMutation,
  useLoginMutation,
  useBySingleUseTokenMutation,
  useRefreshTokenMutation,
  useByJwtMutation,
  useChangeStatusMutation,
  useInviteUserMutation,
  useGetValidateQuery,
  useRequestOwnPasswordResetMutation,
  useRequestPasswordResetMutation,
  useResendInvitationMutation,
  useResendSignupInvitationMutation,
  useResetPasswordMutation,
  useResetTrialMutation,
  useSignupMutation,
  usePatchSubscriptionMutation,
  useSubscriptionMutation,
  useCancelMutation,
  useTosAcceptMutation,
  useGetUserQuery,
  usePatchUserMutation,
  useGetInviteUserQuery,
  useGetPasswordResetQuery,
} = injectedRtkApi;
