import { DrugField } from '@api/enums';
import { titleCase } from '@helpers/textUtils';
import { List, ListItemButton, listItemButtonClasses, styled, Typography, typographyClasses } from '@mui/material';
import { PHARMA_PURPLE } from '@theme/colors';
import { Font } from '@theme/font';
import React from 'react';
import { CiSquarePlus } from 'react-icons/ci';

/**
 * Controls the order of options in the dropdown.
 */
const FIELD_CHOICES = [
    DrugField.label,
    DrugField.indicationsAndUsage,
    DrugField.mechanismOfAction,
    DrugField.descriptionSection,
    DrugField.representedOrganization,
    DrugField.activeIngredient,
    DrugField.inactiveIngredient,
    DrugField.brandName,
    DrugField.dosageAndAdministration,
    DrugField.boxedWarning,
    DrugField.warningsAndPrecautions,
    DrugField.adverseReactions,
    DrugField.contraindications,
    DrugField.drugInteractions,
    DrugField.useInSpecificPopulations,
    DrugField.clinicalStudiesSection,
    DrugField.clinicalPharmacology,
    DrugField.patientCounselingInformation
];

/**
 * Can optionally override the text. Defaults to titleCase.
 */
const NAME_OVERRIDES = {
    [DrugField.label]: 'Search in all sections',
    [DrugField.dosageAndAdministration]: 'Dosage / Administration',
    [DrugField.warningsAndPrecautions]: 'Warnings / Precautions',
    [DrugField.representedOrganization]: 'Company / Packager',
    [DrugField.descriptionSection]: 'Description',
    [DrugField.clinicalStudiesSection]: 'Clinical Studies'
};

export const formatField = (field: DrugField | string): string =>
    NAME_OVERRIDES[field] || titleCase(field);

/**
 * Use a different text for the "all" case.
 */
export const formatFieldForSubtitle = (field: DrugField | string): string => {
    if (field === DrugField.label || !field) {
        return 'All Sections';
    }
    return formatField(field);
}

const IconContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    right: 14,
    transform: 'translateY(-50%)'
});

const FieldList = styled(List)({
    background: '#f4f9fa',
    borderRadius: 4,
    [`& .${listItemButtonClasses.root}`]: {
        borderRadius: 99,
        border: '1px solid #3b92c7',
        background: '#f9f7ee',
        margin: '8px',
        minHeight: '38px',
        padding: '7px 14px',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            opacity: 0
        },
        '&:hover': {
            background: '#ffffff',
            '& svg': {
                opacity: 1,
                background: '#ffffff'
            }
        }
    },
    [`& .${typographyClasses.root}`]: {
        fontSize: 13,
        fontFamily: Font.OpenSans,
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    overflowY: 'auto'
});

interface FieldMenuProps {
    onClickItem: (field: DrugField) => void;
    height: number;
}

export default function FieldMenu({ onClickItem, height = 450 }: FieldMenuProps) {
    return (
        <FieldList
            // TODO: breakpoint for wrapping is based on min widths, so it doesn't match the MUI breakpoints.
            sx={{ maxHeight: { xs: 450, lg: height }}}
        >
            {FIELD_CHOICES.map(key => (
                <ListItemButton key={key} onClick={() => onClickItem(key)}>
                    <Typography>{formatField(key)}</Typography>
                    <IconContainer>
                        <CiSquarePlus color={PHARMA_PURPLE.main} size={24}/>
                    </IconContainer>
                </ListItemButton>
            ))}
        </FieldList>
    );
}
