import type { DrugAutocompleteResponse } from '../../API';
import { urlActiveIngredientAnalytics, urlInactiveIngredientAnalytics, urlSingleDrugAnalytics } from '../../pharmaUrls';

/**
 * matches the names used in the 'metadata' property.
 */
export type AutocompleteType = 'drugs' | 'activeIngredients' | 'inactiveIngredients';

export const getTermsForType = (
    data: DrugAutocompleteResponse | undefined,
    type: AutocompleteType
): string[] => {
    if (!data) return [];
    const index = data.metadata.indexOf(type);
    return data.results
        .filter(result => result.metadata.includes(index))
        .map(result => result.name);
}

export interface AutocompleteOption {
    type: AutocompleteType;
    label: string;
}

export const getTypedObjects = (
    data: DrugAutocompleteResponse | undefined
): AutocompleteOption[] => {
    if (!data) return [];
    // TODO: remove some duplicates
    return data.results.flatMap((result) =>
        result.metadata.map(index => ({
            label: result.name.toLowerCase(),
            type: data.metadata[index] as AutocompleteType
        }))
    );
}

export const optionToKey = (option: AutocompleteOption): string =>
    `${option.label}--${option.type}`;

export const eitherToKey = (value: AutocompleteOption | string): string =>
    typeof value === 'string' ? value : optionToKey(value);

export const eitherToLabel = (value: AutocompleteOption | string): string =>
    typeof value === 'string' ? value : value.label.toLowerCase();

export const metadataToBooleans = (values: number[], labels: string[]): Partial<Record<AutocompleteType, boolean>> =>
    Object.fromEntries(values.map(i => [labels[i], true]));

export const autocompleteOptionUrl = (option: AutocompleteOption): string => {
    const fn = {
        drugs: urlSingleDrugAnalytics,
        activeIngredients: urlActiveIngredientAnalytics,
        inactiveIngredients: urlInactiveIngredientAnalytics
    }[option.type];
    return fn(option.label);
}
