import type { OperatorEnum, SearchTypeEnum } from '@api';
import { camelCase, snakeCase } from 'lodash';
import { typedKeys } from './types';

/**
 * It is easy for TypeScript to convert SNAKE_CASE to camelCase, but not the other way around.
 * So must work backwards from camel to snake.
 */

type SnakeToPascal<T extends string> =
    T extends `${infer A}_${infer B}`
        ? `${SnakeToPascal<A>}${SnakeToPascal<B>}`
        : Capitalize<Lowercase<T>>;

export type SnakeToCamel<T extends string> = Uncapitalize<SnakeToPascal<T>>;

// Can accept any string and will properly infer the type.
export const enumToKey = <E extends string>(uppercase: E) =>
    camelCase(uppercase) as SnakeToCamel<E>;

// Must specify the output enum type because it cannot be inferred.
export const keyToEnum = <E extends string>(key: SnakeToCamel<E>) =>
    snakeCase(key).toUpperCase() as E;

// Generate a type for facet selections based on the filter field type.
export type FacetSelections<E extends string> = Partial<Record<SnakeToCamel<E>, string[]>>

// Generic type for a filter object based on the field type.
export type ApiFilter<E extends string> = {
    field: E;
    values?: (string | number | boolean)[];
    start?: string | number;
    end?: string | number;
    operator?: OperatorEnum;
    searchType?: SearchTypeEnum;
}

export interface FilterOptionalFields {
    operator?: OperatorEnum;
    searchType?: SearchTypeEnum;
}

// Map a dictionary of facet selections to an array of filter objects.
export const facetSelectionsToFilters = <E extends string>(
    selections: FacetSelections<E>
): ApiFilter<E>[] =>
    typedKeys(selections)
        .filter(key => selections[key]?.length)
        .map(key => ({
            field: keyToEnum(key),
            values: selections[key]
        }));

// Map an array of filter objects to a dictionary of facet selections.
export const filtersToFacetSelections = <E extends string>(
    filters: ApiFilter<E>[]
): FacetSelections<E> =>
    filters.reduce<FacetSelections<E>>(
        (selections, filter) => ({
            ...selections,
            [enumToKey(filter.field)]: filter.values ?? []
        }), {});

/**
 * See if an unknown string is a member of an enum.
 * Can provide an enum object or an array of all members.
 */
export const isValidEnum = <E extends string>(
    valid: Record<string, E> | readonly E[],
    value: string
): value is E =>
    Object.values<string>(valid).includes(value);

export const createIsValidEnum = <E extends string>(valid: Record<string, E> | readonly E[]) =>
    (value: string): value is E =>
        isValidEnum(valid, value);

/**
 * Replace any invalid values with a default so that the returned value always fits the enum type.
 */
export const createEnumValidator = <E extends string>(valid: Record<string, E> | E[], fallback: E) => {
    const isValid = createIsValidEnum(valid);
    return (value: string): E =>
        isValid(value) ? value : fallback;
}

/**
 * Generic interface for a sort order with a specific `fieldName` type.
 */
export interface OrderBy<FieldName extends string = string> {
    fieldName: FieldName;
    ascending: boolean;
}

/**
 * Check if an OrderBy object has a valid `fieldName`.
 */
export const createIsValidOrderBy = <E extends string>(valid: Record<string, E> | E[]) => {
    const checkEnum = createIsValidEnum(valid);
    return (orderBy: OrderBy): orderBy is OrderBy<E> =>
        checkEnum(orderBy.fieldName);
}

/**
 * Limit an array of OrderBy objects to only those which match the allowed `fieldName`.
 */
export const validateOrderBy = <E extends string>(
    orderBy: OrderBy[],
    valid: Record<string, E> | E[]
): OrderBy<E>[] =>
    orderBy.filter(createIsValidOrderBy(valid))

const DATE_KEYS = ['date', 'dateReceived', 'dateMerged', 'dateCreated', 'createdDate', 'basilDateCreated', 'decisionDate', 'dateInitiated', 'recallDate', 'lastUpdateDate', 'startDate', 'receiveDate'] as const;

export type DateKey = (typeof DATE_KEYS)[number];

export const isDateKey = createIsValidEnum(DATE_KEYS);
