import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps, NavLink, NavLinkProps } from 'react-router-dom';

/**
 * Handle linking through react-router.
 * Use this LinkBehavior component instead of `a` in Mui Links by default.
 *
 * From docs: https://mui.com/guides/routing/#global-theme-link
 */

export interface HrefAndState {
    href: string;
    state?: any;
}

export type LinkBehaviorProps = Omit<RouterLinkProps, 'to'> & HrefAndState;

const isExternal = (href: string) =>
    href.startsWith('http') || href.startsWith('mailto');

const LinkBehavior = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
    ({ href = '', state, ...props }, ref) => {
        if (state) {
            console.log(`link to ${href} has state:`, state);
        }

        // Check for external links and handle with the native `a` component.
        return isExternal(href) ? (
            <a
                ref={ref}
                href={href}
                target="_blank"
                rel="noopener"
                {...props}
            />
        ) : (
            <RouterLink
                ref={ref}
                to={href}
                state={state}
                {...props}
            />
        )
    }
);

export type LinkBehaviorComponent = typeof LinkBehavior;

export default LinkBehavior;

export const NavLinkBehavior = forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'> & HrefAndState>(
    ({ href, ...props }, ref) => (
        <NavLink
            {...props}
            to={href}
            // Use NavLink `isActive` to add MUI `selected` class.
            className={({ isActive }) => clsx(
                props.className,
                isActive && 'Mui-selected'
            )}
            ref={ref}
        />
    )
)
