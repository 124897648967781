import type { AEChartingKey, PmiLimitToEnum } from '@api';
import { PmiField } from '@api/enums';
import type { CountryCode } from '@content/Country';
import type { AnyFacetKey } from '@content/Facet/formatLabels';
import type { PmiDatasetInDb } from '@features/saved-datasets';
import { makeArray, updateArraySelections } from '@helpers/arrayUtils';
import { ALL_INTERVAL, DateRange } from '@helpers/dateUtils';
import { filtersToFacetSelections, isDateKey } from '@helpers/filterEnums';
import { PossibleArray, StartEnd, typedKeys } from '@helpers/types';
import { ActionCreatorWithOptionalPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq, without } from 'lodash';
// TODO: move this somewhere else
import type { DatasetCore } from '../../Postmarket/data/types';

export type FacetSelections = Partial<Record<AnyFacetKey, string[]>>

export type RangeState = DateRange;
export type RangeSelections = Partial<Record<string, RangeState>>

export interface GroupSelectionState {
    groupName: string;
    isSelectAll: boolean;
    selectedTerms?: string[]; // only needed if the parent group is NOT selected
}

export interface GroupCallbackPayload {
    groupName: string;
    allTermNames: string[];
}

export interface PmiDateState extends DateRange {
    batch?: DateRange;
}

export interface AESelections {
    facets: FacetSelections;
    ranges?: RangeSelections;
    date: PmiDateState;
    query?: string;
    histogram?: AEChartingKey;
    datasetId?: string;
    dataset?: DatasetCore;
    within?: string;
    limitTo?: PmiLimitToEnum;
    country?: CountryCode;
}

interface AppliedDefaults {
    facets: FacetSelections;
    query?: string;
    within?: string;
}

export type SelectionsState = AESelections & {
    ranges: RangeSelections;
    manufacturer: Record<string, GroupSelectionState>;
    // table: TableExtraState;
    isInitialized: boolean;
    isAwaitingDefaults: boolean;
    appliedDefaults: AppliedDefaults; // hold on to for comparing after update.
    initialSelections?: AESelections;
}

const allDates: DateRange = {
    start: null,
    end: null,
    interval: ALL_INTERVAL
}

const initialState: SelectionsState = {
    histogram: 'eventType',
    facets: {},
    ranges: {},
    date: allDates,
    manufacturer: {},
    isInitialized: false,
    isAwaitingDefaults: true,
    appliedDefaults: {
        facets: {}
    },
    query: '' // Note: prevents duplicate queries after clearing.
};

export interface FacetChangePayload {
    field: AnyFacetKey;
    value: string | string[];
    isSelected: boolean;
}

type PayloadCommon = {
    value: string;
    isSelected: boolean;
}

type ManuFacetChangePayload = PayloadCommon & {
    group: GroupCallbackPayload;
}

type ManuGroupChangePayload = PayloadCommon;

export interface FacetReplacePayload {
    field: AnyFacetKey;
    values: string[];
}

export interface MoveSelectionsPayload {
    from: AnyFacetKey | AnyFacetKey[];
    to: AnyFacetKey;
}

export interface ClearAllFacetsPayload {
    clearDate?: boolean;
    clearRanges?: boolean;
}

export type RangeChangePayload = {
    rangeKey: string
} & (RangeState | StartEnd<string | number | null>);

const slice = createSlice({
    name: 'aeSelection',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        setDateRange: (state, action: PayloadAction<DateRange>) => {
            state.date = action.payload; // Clears batch
        },
        clearDateRange: (state) => {
            state.date = allDates;
        },
        openBatch: (state, action: PayloadAction<DateRange>) => {
            state.facets = {};
            delete state.query;
            state.date.batch = action.payload;
        },
        clearBatch: (state) => {
            delete state.date.batch;
        },
        setHistogramType: (state, action: PayloadAction<AEChartingKey>) => {
            state.histogram = action.payload;
        },
        handleWithinChange: (state, action: PayloadAction<Pick<AESelections, 'within' | 'limitTo'>>) => {
            state.within = action.payload.within || undefined;
            state.limitTo = action.payload.limitTo || undefined;
        },
        setCountry: (state, action: PayloadAction<CountryCode>) => {
            state.country = action.payload;
            // TODO: this is for the matrix, but is it universal?
            state.facets = {};
        },
        // X button on AE preserves initial selections of procode or app.
        resetInitialFacets: (state) => {
            state.facets = state.initialSelections?.facets || {};
            state.manufacturer = {};
        },
        // X button on Pmi clears everything.
        clearAllFacets: (state, action: PayloadAction<ClearAllFacetsPayload | undefined>) => {
            state.facets = {};
            state.manufacturer = {};
            if (action.payload?.clearDate) {
                state.date = allDates;
            }
            if (action.payload?.clearRanges) {
                state.ranges = {};
            }
        },
        clearFacet: (state, action: PayloadAction<PossibleArray<AnyFacetKey>>) => {
            makeArray(action.payload).forEach(facetKey => {
                delete state.facets[facetKey];
                if (facetKey === 'manufacturer') {
                    state.manufacturer = {};
                }
            });
        },
        changeFacetSelection: (state, action: PayloadAction<FacetChangePayload>) => {
            const { field, isSelected, value } = action.payload;
            const terms = makeArray(value, true);
            const current = state.facets[field] ?? [];
            state.facets[field] = updateArraySelections(current, terms, isSelected);
        },
        setManuGroupSelections: (state, action: PayloadAction<GroupSelectionState>) => {
            // Note: reason for handling complex logic in hook instead of here is
            // the difficulty of deselecting a facet from a selectAll group.
            state.manufacturer[action.payload.groupName] = action.payload;
        },
        changeManuFacetSelection: (state, action: PayloadAction<ManuFacetChangePayload>) => {
            const { group, value, isSelected } = action.payload;
            const { groupName, allTermNames } = group;
            const current = state.manufacturer[groupName];
            // selecting from group for the first time
            if (!current) {
                if (isSelected) {
                    state.manufacturer[groupName] = {
                        groupName,
                        isSelectAll: false,
                        selectedTerms: [value]
                    }
                }
            } else {
                const currentTerms = current.isSelectAll ? allTermNames : current.selectedTerms || [];
                // checking a facet
                if (isSelected) {
                    current.selectedTerms = currentTerms.concat(value);
                    current.isSelectAll = allTermNames.every(term => current.selectedTerms?.includes(term));
                }
                // unchecking a facet
                else {
                    current.selectedTerms = without(currentTerms, value);
                    current.isSelectAll = false;
                }
            }
        },
        changeManuGroupSelection: (state, action: PayloadAction<ManuGroupChangePayload>) => {
            const { value, isSelected } = action.payload;
            if (isSelected) {
                // clear the selected terms array when selecting all
                state.manufacturer[value] = {
                    groupName: value,
                    isSelectAll: true
                }
            } else {
                // delete the entry if nothing is selected
                delete state.manufacturer[value];
            }
        },
        replaceFacetSelections: (state, action: PayloadAction<FacetReplacePayload>) => {
            const { field, values } = action.payload;
            state.facets[field] = values;
        },
        // Reassign the selections from one or more facet keys to a different facet key.
        moveFacetSelections: (state, action: PayloadAction<MoveSelectionsPayload>) => {
            const { from, to } = action.payload;
            makeArray(from).forEach(key => {
                if (key !== to && state.facets[key]?.length) {
                    // Note: may want to uniq.
                    state.facets[to] = (state.facets[to] || []).concat(state.facets[key]);
                    delete state.facets[key];
                }
            });
        },
        setRangeSelection: (state, action: PayloadAction<RangeChangePayload>) => {
            const { rangeKey, start, end, ...rest } = action.payload;
            state.ranges[rangeKey] = {
                // Accept numbers but cast to string
                start: start === null ? null : String(start),
                end: end === null ? null : String(end),
                ...rest
            };
        },
        clearRangeSelection: (state, action: PayloadAction<string>) => {
            delete state.ranges[action.payload];
        },
        // Need to initialize the state with initial selections before loading data
        handleMount: (state, action: PayloadAction<Partial<AESelections>>) => ({
            ...initialState,
            ...action.payload,
            isInitialized: true,
            // If there is no dataset id, it will be ready now.
            isAwaitingDefaults: !!action.payload.datasetId,
            initialSelections: {
                ...initialState,
                ...action.payload
            }
        }),
        // Note: Call explicitly instead of using `matchFulfilled` in case it's rejected due to existing data.
        // Don't remove any current selections unless those selections came from the previous defaults.
        receiveDefaultFilters: (state, action: PayloadAction<PmiDatasetInDb>) => {
            const filters = (action.payload.filters ?? []);
            const keyedSelections = filtersToFacetSelections(filters as any);
            // Select new filters & deselect old filters.
            typedKeys(PmiField).forEach(field => {
                // TODO: special handling for date filter.
                if (isDateKey(field) || field === 'reviewPanel') return;
                const current = state.facets[field] ?? [];
                const oldDefaults = state.appliedDefaults.facets[field] ?? [];
                const newDefaults = keyedSelections[field] ?? [];
                // Note: setting every field even if empty might cause unnecessary re-renders?
                state.facets[field] = uniq(without(current, ...oldDefaults).concat(...newDefaults));
            });
            state.within = state.within || action.payload.within;
            state.query = state.query || action.payload.query;
            // Save these filters for the next comparison.
            state.appliedDefaults = {
                facets: keyedSelections,
                within: action.payload.within,
                query: action.payload.query
            };
            state.isAwaitingDefaults = false;
        },
        resetToDefaults: (state) => {
            state.facets = state.appliedDefaults.facets;
            state.within = state.appliedDefaults.within;
            state.query = state.appliedDefaults.query;
        },
        // Not a complete replacement, will shallow merge.
        replaceState: (state, action: PayloadAction<Partial<AESelections>>) => {
            return {
                ...state,
                ...action.payload,
                isInitialized: true
            } // Note: could set initialSelections if first
        },
        handleUnmount: () => initialState,
    }
})

export const {
    clearFacet,
    resetInitialFacets,
    changeFacetSelection,
    changeManuFacetSelection,
    changeManuGroupSelection,
    handleMount,
    handleUnmount,
    replaceFacetSelections,
    moveFacetSelections,
    setDateRange,
    clearDateRange,
    setRangeSelection,
    clearRangeSelection,
    openBatch,
    clearBatch,
    setHistogramType,
    setManuGroupSelections,
    setQuery,
    setCountry,
    handleWithinChange,
    receiveDefaultFilters,
    resetToDefaults,
    replaceState
} = slice.actions;

// Fixes TS issues with optional payload.
export const clearAllFacets = slice.actions.clearAllFacets as ActionCreatorWithOptionalPayload<ClearAllFacetsPayload>;

export default slice.reducer;
