import { withQueryVars } from '@hooks/useUrlQuery';
import { SearchResultsLocationState } from './pages/useLocationState';

export const urlSingleDrugAnalytics = (drugBrand: string) =>
    `/pharma/analytics/drug/${encodeURIComponent(drugBrand.toLowerCase())}`;

export const urlActiveIngredientAnalytics = (name: string) =>
    `/pharma/analytics/active-ingredient/${encodeURIComponent(name.toLowerCase())}`;

export const urlInactiveIngredientAnalytics = (name: string) =>
    `/pharma/analytics/inactive-ingredient/${encodeURIComponent(name.toLowerCase())}`;

export const urlSearchResultsAnalytics = () =>
    `/pharma/analytics/search`;

export const urlNewSearch = () =>
    `/pharma/search`;

export const urlNewLabelSearch = () =>
    `/pharma/search/labels`;

export const urlTrialsForLabels = () =>
    `/pharma/search/label-trials`;

// identity function for type-safety
export const searchResultsState = (state: SearchResultsLocationState) => state;

export const urlDrugLabeling = (
    drugBrand: string, // Should be the name from the data, not the slug from the URL
    labelId?: string
) => {
    const base = `/pharma/labels/us/drug/${encodeURIComponent(drugBrand.toLowerCase())}`;
    return labelId ? `${base}/${labelId}` : base;
}

export interface IngredientUniiParamsRaw {
    active?: string[];
    inactive?: string[];
}

export interface IngredientUniiParamsParsed {
    active?: { name?: string; unii?: string }[];
    inactive?: { name?: string; unii?: string }[];
}

const SEPARATOR = '__';

export const stringifyUnii = (code: string, name: string) => `${code}${SEPARATOR}${name}`

export const parseUnii = (combined: string) => {
    const parts = combined.split(SEPARATOR);
    // Note: if separator is not found, name will be empty. Can use this to filter later.
    const [code, name = ''] = parts;
    return { name, unii: code };
}

export const urlUniiIngredientAnalytics = (active: Record<string, string>, inactive: Record<string, string>) => {
    const queryVars = {
        active: Object.entries(active).map(([code, name]) => stringifyUnii(code, name)),
        inactive: Object.entries(inactive).map(([code, name]) => stringifyUnii(code, name))
    }
    return withQueryVars(
        `/pharma/analytics/ingredients`,
        queryVars
    );
}

export const urlTrialDetails = (nctId: string) =>
    `/pharma/clinical-trial/${nctId}`;

export const urlGuidanceDetails = (primaryIdHash: string) =>
    `/pharma/guidance/${primaryIdHash}`;