import { Count, Drug, DrugAutocompleteResponse, DrugResponse, DrugSearchResponse, Ingredient } from '../../API';
import { metadataToBooleans } from '../Search/autocompleteUtil';

export const totalCount = (drug: { count?: Count; }): number =>
    (drug.count?.clinicalTrial || 0) + (drug.count?.faers || 0);

/**
 * Prefer an exact text match if `matchBrand` is specified.
 * Otherwise return the drug with the most events.
 */
export const pickInitialIndex = (drugs: Drug[], matchBrand?: string): number => {
    if (matchBrand) {
        const matching = (drugs || []).findIndex(drug => drug.brandName === matchBrand); // TODO: is everything uppercase?
        if (matching !== -1) return matching;
    }
    return (drugs || []).reduce(
        (acc, curr, i, arr) =>
            (totalCount(curr) > totalCount(arr[acc])) ? i : acc
        , 0);
}

/**
 * Prefer an ingredient which matches the search term.
 * Otherwise return the first in the array.
 * TODO: could pick by count, if the count is provided.
 */
export const pickActiveIngredient = (ingredients: Ingredient[], matchQuery: string): Ingredient => {
    const matching = ingredients.find(ing => ing.name === matchQuery); // TODO: is everything uppercase?
    if (matching) return matching;
    return ingredients[0];
}

export const hasIngredientMatches = (data: DrugResponse): boolean =>
    data.activeIngredients.length > 0;

// Choose the top 5 drugs and ingredients, based on the order from the API.
export const pickMultiInitial = (data: DrugSearchResponse) => ({
    actives: (data?.activeIngredients || []).slice(0, 5).map(o => o.name),
    brands: (data?.drugs || []).slice(0, 5).map(o => o.brandName)
});

/**
 * Check the results of the autocomplete endpoint to see if this term is an ingredient.
 * Only look at the first result.
 * Do nothing if it is a drug only.
 * Prefer ingredient when it is both a drug and an ingredient.
 * Prefer active when it is both active and inactive.
 */
export const ingredientFromAutocomplete = (data: DrugAutocompleteResponse | undefined): undefined | { name: string; isActive: boolean } => {
    const result = data?.results[0];
    if (!result) return;
    const flags = metadataToBooleans(result.metadata, data.metadata);
    if (!flags.activeIngredients && !flags.inactiveIngredients) return; // drug only
    return {
        name: result.name,
        isActive: !!flags.activeIngredients
    };
}
