import type { QuerySelections } from '@features/faceted-search/queries/genericPrepare';

const standardNames = new Intl.DisplayNames('en', {
    type: 'region',
    fallback: 'code'
});

/**
 * Get the name for any country code, not just the Basil-allowed.
 * Note: will be an invalid_argument error if the code is not a two-digit pair,
 * Like if it is "NULL" or "N/A"
 */
export const countryName = (code: string): string => {
    try {
        return standardNames.of(code.toUpperCase());
    } catch (e) {
        return code;
    }
}

/**
 * Country codes supported for matrix APIs.
 */
export type CountryCode = 'au' | 'ca' | 'us';

export const COUNTRY_CODES: CountryCode[] = ['au', 'ca', 'us'];

export const isUsa = (code: CountryCode | null | undefined): boolean =>
    code === 'us' || !code;

export const isIntl = (code: CountryCode | null | undefined): code is 'au' | 'ca' =>
    !isUsa(code);

// No trailing slash
export const globalizeApiUrl = (base: string, country?: CountryCode): string =>
    isIntl(country) ? `${base}/international/${country}` : base;

export const countryFromSelections = (selections: QuerySelections | QuerySelections[]): CountryCode =>
    (Array.isArray(selections) ? selections[0]?.country : selections.country) || 'us';
